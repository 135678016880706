import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import FormInput from "../form/FormInput";
import DefaultButton from "../buttons/DefaultButton";
import FormCheckbox from "../form/FormCheckbox";

class ThemeModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            number: '',
            default: false,
            error: null
        }
    }

    validate = () => {
        let number = this.props.number;
        if (number.length < 10) {
            return 'Please enter a valid phone number';
        }
        if (number.length > 11) {
            return 'Please enter a valid phone number';
        }
        if (number.length > 10 && !number.startsWith('1')) {
            return 'Please enter a valid phone number';
        }
        return false;
    };

    submit = () => {
        let error = this.validate();
        if(error){
            this.setState({error:error});
            return;
        }
        let number = this.props.number;
        if(number.length === 10){
            number = `1${number}`
        }
        this.props.onSubmit(number, this.state.default);
    };

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.onClose} centered className="modal patientsModal">
                <div className="modal-body py-4 d-flex flex-column align-items-center">
                    <h5 className="modal-title mb-3" id="exampleModalLongTitle">Add New Number</h5>

                    <form style={{display: 'contents'}}>
                        <div className="otp-input">
                            <FormInput error={!!this.state.error} label="Enter New Number" value={this.props.number} onChange={this.props.onChange}/>

                            <div className="d-flex flex-column">
                                <FormCheckbox name="Set as a default number" value="1" id="1"
                                              checked={this.state.default} onChange={() => {
                                    this.setState({default: !this.state.default});
                                }}/>
                            </div>
                        </div>
                        <div className="pt-4 mt-2 d-flex flex-row justify-content-center">
                            <DefaultButton className="blue-button mr-2" name="Submit" onClick={this.submit}/>

                            <DefaultButton className="cancel-button" name="Close" onClick={this.props.onClose}/>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }
}

export default ThemeModal;
