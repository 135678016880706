import React, {Component} from 'react';
import {Card, Form,} from "react-bootstrap";
import ThemeHeader from "../../components/themeHeading/ThemeHeader";
import FormInput from "../../components/form/FormInput";
import Col from "react-bootstrap/Col";
import DefaultButton from "../../components/buttons/DefaultButton";
import Api from "../../../api/Api";

const fields = {
    username: {
        label: "Username",
        type: "text",
        defaultValue: "harrytestaccount3",
        required: true,
        errorMessage: "Username is required"
    },
    password: {
        label: "Password",
        type: "text",
        defaultValue: "harry@123",
        required: true,
    },
    firstname: {
        label: "First Name",
        type: "text",
        defaultValue: "Hardeep",
        required: true,
    },
    lastname: {
        label: "Last Name",
        type: "text",
        defaultValue: "Singh",
        required: true,
    },
    email: {
        label: "Email",
        type: "text",
        defaultValue: "hardeep@itmatic.io",
        required: true,
    },
    company_name: {
        label: "Company Name",
        type: "text",
        defaultValue: "ITMatic",
        required: true,
    },
    address: {
        label: "Address 1",
        type: "text",
        defaultValue: "SCF 10-11",
        required: true,
    },
    address_2: {
        label: "Address 2",
        type: "text",
        defaultValue: "MDC 4",
        required: false,
    },
    city: {
        label: "City",
        type: "text",
        defaultValue: "Panchkula",
        required: true,
    },
    state: {
        label: "State",
        type: "text",
        renderAs: 'select',
        options: [
            {value: '', label: ""},
            {value: 4, label: "Alabama"},
            {value: 5, label: "Alaska"},
            {value: 59, label: "Alberta"},
            {value: 6, label: "Arizona"},
            {value: 7, label: "Arkansas"},
            {value: 58, label: "British Columbia"},
            {value: 8, label: "California"},
            {value: 9, label: "Colorado"},
            {value: 10, label: "Connecticut"},
            {value: 11, label: "Delaware"},
            {value: 12, label: "District of Columbia"},
            {value: 13, label: "Florida"},
            {value: 14, label: "Georgia"},
            {value: 15, label: "Hawaii"},
            {value: 16, label: "Idaho"},
            {value: 17, label: "Illinois"},
            {value: 18, label: "Indiana"},
            {value: 19, label: "Iowa"},
            {value: 20, label: "Kansas"},
            {value: 21, label: "Kentucky"},
            {value: 22, label: "Louisiana"},
            {value: 23, label: "Maine"},
            {value: 60, label: "Manitoba"},
            {value: 24, label: "Maryland"},
            {value: 25, label: "Massachusetts"},
            {value: 26, label: "Michigan"},
            {value: 27, label: "Minnesota"},
            {value: 28, label: "Mississippi"},
            {value: 29, label: "Missouri"},
            {value: 30, label: "Montana"},
            {value: 31, label: "Nebraska"},
            {value: 32, label: "Nevada"},
            {value: 63, label: "New Brunswick"},
            {value: 33, label: "New Hampshire"},
            {value: 34, label: "New Jersey"},
            {value: 35, label: "New Mexico"},
            {value: 36, label: "New York"},
            {value: 64, label: "Newfoundland & Labrador"},
            {value: 37, label: "North Carolina"},
            {value: 38, label: "North Dakota"},
            {value: 66, label: "Northwest Territories"},
            {value: 62, label: "Nova Scotia"},
            {value: 68, label: "Nunavut"},
            {value: 39, label: "Ohio"},
            {value: 40, label: "Oklahoma"},
            {value: 56, label: "Ontario"},
            {value: 41, label: "Oregon"},
            {value: 55, label: "Other"},
            {value: 42, label: "Pennsylvania"},
            {value: 65, label: "Prince Edward Island"},
            {value: 69, label: "Puerto Rico"},
            {value: 57, label: "Quebec"},
            {value: 43, label: "Rhode Island"},
            {value: 61, label: "Saskatchewan"},
            {value: 44, label: "South Carolina"},
            {value: 45, label: "South Dakota"},
            {value: 46, label: "Tennessee"},
            {value: 47, label: "Texas"},
            {value: 70, label: "US Virgin Islands"},
            {value: 48, label: "Utah"},
            {value: 49, label: "Vermont"},
            {value: 50, label: "Virginia"},
            {value: 51, label: "Washington"},
            {value: 52, label: "West Virginia"},
            {value: 53, label: "Wisconsin"},
            {value: 54, label: "Wyoming"},
            {value: 67, label: "Yukon"}
        ],
        defaultValue: 47,
        required: true,

        errorMessage: "State is required"
    },
    zip: {
        label: "Zip",
        type: "text",
        defaultValue: "13410",
        required: true,
    },
    phone: {
        label: "Phone",
        type: "text",
        defaultValue: "9467344530",
        required: true,
    },
    timezone: {
        label: "Time Zone",
        type: "text",
        renderAs: 'select',
        options: [
            {label: '', value: ''},
            {label: 'Hawaii-Aleutian', value: -2},
            {label: 'Alaska', value: -1},
            {label: 'Pacific Time', value: 0},
            {label: 'Mountain Time', value: 1},
            {label: 'Central Time', value: 2},
            {label: 'Eastern Time', value: 3},
            {label: 'Atlantic Time', value: 4},
        ],
        defaultValue: 0,
        required: true,

        errorMessage: "Time Zone is required"

    },
};

let initialAccount = {
    source_acct_guid: "{1D8F07E3-855A-445C-AAC8-DC6F1633DBD1}"
};

const Production = true;

Object.keys(fields).map(key => {
    if (fields[key].defaultValue) {
        initialAccount[key] = fields[key].defaultValue;
        if (Production) {
            initialAccount[key] = '';
        }
    }
    return null;
});

class CreateAccount extends Component {


    constructor(props) {
        super(props);

        this.state = {
            creating: false,
            account: initialAccount,
            errors:{}
        };
    }

    componentDidMount() {
        if (this.state.creating) {
            this.checkAccountCreationStatus({});
        }
    }

    validate = () => {
        let valid = true;
        let errors = {};
        Object.keys(fields).map(key => {
            if (fields[key].required && !this.state.account[key]) {
                valid = false;
                errors[key] = fields[key].errorMessage || "Invalid Value";
            }
            return null;
        });
        this.setState({errors:errors});
        return valid;
    };


    createAccount = () => {
        if (!this.validate()) {
            return;
        }
        this.setState({creating: true});
        Api.call("/accounts/create", "POST", this.state.account)
            .then((request) => {
                this.checkAccountCreationStatus(request);
            })
            .catch(error => {
                this.setState({creating: false});
                alert(error);
            });
    };

    checkAccountCreationStatus = (request) => {

        Api.call(`/accounts/create/status`, "POST", {request: request.guid})
            .then(response => {
                if (response.created) {
                    this.props.history.push(`/account/account-detail?guid=${response.guid}`)
                } else {
                    this.checkAccountCreationStatus(request);
                }
            }).catch(error => {
            alert("Error: " + error);
        }).finally(() => {
            this.setState({account: initialAccount});
        })
    };

    renderLoading = () => {
        return <Card className="mb-3">
            <ThemeHeader icon={'../../../images/account.png'} cardHeader="Create Account"/>
            <Card.Body>
                <Form.Row className="d-flex justify-content-center">
                    <span>Please wait...</span>
                </Form.Row>
            </Card.Body>
        </Card>
    };

    render() {
        if (this.state.creating) {
            return this.renderLoading();
        }
        return (
            <>
                <Card className="mb-3">
                    <ThemeHeader icon={'../../../images/account.png'} cardHeader="Create Account"/>
                    <Card.Body>
                        <Form>
                            <Form.Row>
                                {Object.keys(fields).map(key => {
                                    return <Col key={key} md={6}>
                                        <FormInput error={this.state.errors[key]} label={fields[key].label} value={this.state.account[key]}
                                                   renderAs={fields[key].renderAs} options={fields[key].options}
                                                   onChange={(e) => {
                                                       let account = this.state.account;
                                                       account[key] = e.target.value;
                                                       this.setState({account: account})
                                                   }}/>
                                    </Col>
                                })}
                            </Form.Row>
                            {!this.state.creating &&
                            <Form.Row className="d-flex justify-content-center">
                                <DefaultButton className="blue-button mr-2" name="Submit"
                                               onClick={this.createAccount}/>
                                <DefaultButton link={'/account'} className="cancel-button" name="Cancel"/>
                            </Form.Row>
                            }
                            {this.state.creating &&
                            <div className="loading-bar">
                                <img alt="Loading" width={50} src="../../../../images/loading.gif"/>
                            </div>
                            }
                        </Form>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

export default CreateAccount;
