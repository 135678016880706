import React, {Component} from 'react';
import classNames from 'classnames';
import {Link} from "react-router-dom";
import paginate from "../../../pagination/Paginate";


class Pagination extends Component {

    render() {
        let {currentPage,totalItems,perPage} = this.props;
        let totalPages = Math.ceil(totalItems/perPage);

        let {pages} = paginate(this.props.totalItems,this.props.currentPage,this.props.perPage);
        return (
            <div className="paginationCol pt-2">
                <ul className="pagination">
                    <li className="page-item">
                        <Link  onClick={() => {
                            if(currentPage === 1){
                                return;
                            }
                            this.props.onPaginate(this.props.currentPage-1)
                        }} className="page-link" href="#">&laquo;</Link>
                    </li>

                    {pages.map(page => {
                        return <li key={page} className={classNames("page-item", {'active': page === this.props.currentPage})}>
                            <Link onClick={()=>this.props.onPaginate(page)} page={page} buttonText={page}
                                              className="page-link">
                                {page}
                            </Link>

                        </li>
                    })}

                    <li className="page-item">
                        <Link  onClick={() => {
                            if(totalPages === currentPage){
                                return;
                            }
                            this.props.onPaginate(this.props.currentPage+1)
                        }} className="page-link" href="#">&raquo;</Link>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Pagination;
