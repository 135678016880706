import React, { Component } from "react";
import ThemeHeader from "../../components/themeHeading/ThemeHeader";
import Table from "../../components/Table/Table";
import Api from "../../../api/Api";
import Constants from "../../../utils/Constants";
import Overlay from "../../components/overlay/Overlay";
import Switch from '@material-ui/core/Switch'
import { Modal,  OverlayTrigger , Tooltip } from "react-bootstrap";
import PersistenceModal from "../../components/modal/PersistenceModal";
import { toast } from 'react-toastify';
import { Link } from "@material-ui/core";

class AllAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 8,
      total: 0,
      keyword: "",
      loading: true,
      error: null,
      sortBy: "companyName",
      sortDirection: "asc",
      columns: [
        { name: "Account Name" },
        { name: "Account Id"},
        { name: "Contact Number" },
        { name: "Email Address" },
        { name: "ZForms Date" },
        { name: "Echo" },
        { name: "Persistence" },
        { name: "ZForms" },
        { name: "ZSuite" },
        { name: "Missed Appointment Alerts" }
      ],
      overlayActive: false,
      currentData: [],
      accounts: [],
      persistenceModal: false,
      selectedIndex: -1,
      selectedDate: new Date(),
      flyout:false,
      infoflyout:false,
      locationId: "",
      isChecked: false,
      errorMessage: false,
      rowIndexN: "",
      loader: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.flyoutRef = React.createRef();
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  showModal = (settings) => {
    this.setState({ persistenceModal: true });
  };

  hideModal = () => {
    this.setState({ persistenceModal: false });
  };

  componentDidMount() {
    this.loadAccounts();
    document.addEventListener("mousedown", this.handleClickOutside); 
  }
  
  onPaginate = (page) => {
    this.setState({ page: page }, this.loadAccounts);
  };

  toggleSortDirection = (sortDirection, sortBy) => {
    this.setState({ sortDirection: sortDirection, sortBy: sortBy }, this.loadAccounts);
  };

  loadAccounts = () => {
    Api.call(
      Constants.GET_ACCOUNTS_LIST +
      `${this.state.keyword.length === 0 ? " " : this.state.keyword}&limit=${this.state.limit
      }&page=${this.state.page}&sortBy=${this.state.sortBy}&sortDirection=${this.state.sortDirection
      }&onlyForAmt=1`
    )
      .then((accounts) => {
        this.setState({
          accounts: accounts.data.data,
          total: accounts.data.totalRecords,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  parseRow = (row, index) => {
    return [row.companyName,row.accountNumber, row.phoneNumber, row.email, row.settings?.enableZFormsDate, 0, 0, 0, 0,0];
  };

 // location id handle function
  handleChange =(e)=>{
    const val = e.target.value;
    this.setState({locationId : val, errorMessage : false, loader: false})
  }

  // toggle switch handle function
  updateEnableZingit2=(rowIndex)=>{
    if(!this.state.accounts[rowIndex].EnableZingit2){
      this.state.accounts[rowIndex].flyout = true
      this.setState({ accounts: this.state.accounts, rowIndexN: rowIndex});
    }
    else{
      const clientId=this.state.accounts[rowIndex].clientId;
      const locationId=this.state.accounts[rowIndex].locationId;
      this.state.accounts[rowIndex].EnableZingit2 = !this.state.accounts[rowIndex].EnableZingit2 ? 1 : 0;
      this.setState({ accounts: this.state.accounts});
      if((this.state.accounts[rowIndex].EnableZingit2 === 0 )){
          Api.coreAPICall(Constants.UPDATE_ENABLE_ZINGIT_FLAG +`clientId=${clientId}&locationId=${locationId}` , 'GET')
          .then((response) => {
            if(response[0].DoctibleStatus === 1){
              toast.success(response[0].Message);
              this.state.accounts[rowIndex].EnableZingit2 = 0
            }else{
              toast.error(response[0].Message);
              this.loadAccounts();
          }
          })
          .finally(() => {
            this.setState({ loading: false });
          });
           }
    }

  }


  // add location id after submit function
  submitLoction= async (rowIndex)=>{
    const clientId= this.state.accounts[rowIndex].clientId;
    if(!this.state.locationId){
      this.setState({errorMessage : true})
      return
    }
    this.setState({loader: true })
    Api.coreAPICall(Constants.UPDATE_ENABLE_ZINGIT_FLAG +`clientId=${clientId}&locationId=${this.state.locationId}`  , 'GET')
      .then((response) => {
        // console.log(response)
        if(response[0].DoctibleStatus === 1){
          toast.success(response[0].Message);
          this.state.accounts[rowIndex].flyout = false;
          this.state.accounts[rowIndex].EnableZingit2 = 1;
          this.setState({ accounts: this.state.accounts, locationId : "", errorMessage : false, loader: false});
          this.loadAccounts();
        }else{
          toast.error(response[0].Message);
      }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  
  // cancel location flyout close function
  cancelLoction= async (rowIndex) =>{
    this.state.accounts[rowIndex].flyout = false;
    this.state.accounts[rowIndex].infoflyout = false;
    this.setState({ accounts: this.state.accounts,  errorMessage : false});
    this.loadAccounts();
  }

  // outer click close location flyout function
  handleClickOutside = async (event) => {
    let rowIndexN = this.state.rowIndexN;
    if (this.flyoutRef.current && !this.flyoutRef.current.contains(event.target)) {
      this.state.accounts[rowIndexN].flyout = false;
      this.state.accounts[rowIndexN].infoflyout = false;
      this.setState({ errorMessage : false});
      this.loadAccounts();
    }
  };

  submitLoctionID=async(rowIndex)=>{
    let request = {};
      request['clientId'] = this.state.accounts[rowIndex].clientId;
      request['locationId'] =this.state.locationId;
     Api.coreAPICall(Constants.UPDATE_LOCATION_ID, 'PUT', request)
    .then((response)=>{
      if(response[0].Status===1){
        toast.success(response[0].Message);
        this.state.accounts[rowIndex].flyout = false;
        this.state.accounts[rowIndex].infoflyout = false;
        this.setState({ accounts: this.state.accounts,  errorMessage : false});
        this.loadAccounts();
      }else{
        toast.error(response[0].Message);
      }
    })
    .finally(() => {
      this.setState({ loading: false });
    });
  }

  handleLocationChange =(e,rowIndex)=>{
    this.state.accounts[rowIndex].locationId = e.target.value
    this.setState({locationId: e.target.value});
  }

  // update location id 
  updateLocationID= async(rowIndex)=>{
    this.state.accounts[rowIndex].infoflyout = true;
    this.setState({ accounts: this.state.accounts, rowIndexN: rowIndex});
  }
  renderRow = (row, index) => {
    row = this.parseRow(row, index);
    let columnIndex = 0;
    return (
      <tr key={row.id}>
        {row.map((column) => {
          return this.renderColumn(column, columnIndex++, index, row);
        })}
      </tr>
    );
  };

  renderColumn = (column, index, rowIndex) => {
    if (index === 0) {
      return (
        <td
          className={
            this.state.makeActive === rowIndex
              ? "td-pointer td-highlight"
              : "td-pointer"
          }
          onClick={() => this.showAccountDetail(column, rowIndex)}
          key={index}
        >
         {column} 
        </td>
      );
    }
    // if (index === 3) {return (<td>
    //   {<div class="enable-disab-auto">
    //     <Switch checked={!!this.state.accounts[rowIndex].settings?.enableCache} onChange={()=>{this.updateFlag('enableCache', rowIndex)}}/>
    //     {/*<span className="on-text">{this.state.accounts[rowIndex].settings?.enableCache ? 'On':'Off'}</span>*/}
    //   </div>}
    // </td>)}
    if (index === 5) {
      return (<td style={{ width: 100 }}>
        <>
        {<div className="enable-disab-auto">
        <Switch disabled={this.state.accounts[rowIndex].clientId === null|| false} checked={this.state.accounts[rowIndex].EnableZingit2===1} onChange={() => {this.updateEnableZingit2(rowIndex) }} />
         </div>}
         
         {(this.state.accounts[rowIndex].flyout) && 
          <div ref={this.flyoutRef} className="flyout-outer">
            <div  className="flyout arrow">
                <form>
                  <div className="fly-inner d-flex">
                    <input type="text" placeholder="Please add Echo unique ID" className={this.state.errorMessage ? "form-control error" : "form-control"} onChange={this.handleChange} />
                    <Link className="btn btn-secondary" onClick={() => {this.cancelLoction(rowIndex) }} >Cancel</Link>
                    <Link className={!this.state.loader ? "btn btn-primary" : "btn btn-primary disabled"} onClick={() => {this.submitLoction(rowIndex) }}>
                      { !this.state.loader ?  "Submit" :  "Wait..." }
                    </Link>
                </div>
                </form>
            </div>
          </div>
          } 
        </>
      </td>)
    }
    if (index === 6) {
      return (<td style={{ width: 100 }}>
        {<div class="enable-disab-auto">
          <Switch checked={(!!this.state.accounts[rowIndex].settings?.enableZingitPersistence?.fullPersistence || !!this.state.accounts[rowIndex].settings?.enableZingitPersistence?.partialPersistence)} onChange={(e) => { this.updateFlag('enableZingitPersistence', rowIndex) }} />
          {/*<span className="on-text">{this.state.accounts[rowIndex].settings?.enableZingitPersistence ?'On':'Off'}</span>*/}
        </div>}
      </td>)
    }
    if (index === 7) {
      return (<td>
        {<div class="enable-disab-auto">
          <Switch checked={!!this.state.accounts[rowIndex].settings?.enableZForms} onChange={() => { this.updateFlag('enableZForms', rowIndex) }} />
          {/*<span className="on-text">{this.state.accounts[rowIndex].settings?.enableZForms ?'On':'Off'}</span>*/}
        </div>}
      </td>)
    }
    if (index === 8) {
      return (<td>
        {<div class="enable-disab-auto">
          <Switch checked={!!this.state.accounts[rowIndex].settings?.enableZSuite} onChange={() => { this.updateFlag('enableZSuite', rowIndex) }} />
          {/*<span className="on-text">{this.state.accounts[rowIndex].settings?.enableZForms ?'On':'Off'}</span>*/}
        </div>}
      </td>)
    }




    if (index === 9) {
      return (<td>
       
          <>
          <div className="toggle-info">
            <div class="enable-disab-auto">
          <Switch checked={!!this.state.accounts[rowIndex].settings?.enableMissedAppointmentAlerts} onChange={() => { this.updateFlag('enableMissedAppointmentAlerts', rowIndex); } } />
              {/*<span className="on-text">{this.state.accounts[rowIndex].settings?.enableZForms ?'On':'Off'}</span>*/}
            </div>
            <div>

            <OverlayTrigger placement="top" overlay={<Tooltip>Edit & View Echo unique Id.</Tooltip>}>
            <img onClick={() => {this.updateLocationID(rowIndex)}} className="info-icon" src="../../../../../images/infoIcon.svg" alt="info icon" />
          </OverlayTrigger>
              {this.state.accounts[rowIndex].infoflyout && 
              <div ref={this.flyoutRef} className="flyout-outer">
                <div  className="flyout arrow">
                    <form>
                      <div className="fly-inner d-flex">
                         <input type="text"value={this.state.accounts[rowIndex].locationId} className={this.state.errorMessage ? "form-control error" : "form-control"}
                     onChange={(e)=>this.handleLocationChange(e,rowIndex)}
                     /> 
                        <Link className="btn btn-secondary" onClick={() => {this.cancelLoction(rowIndex) }} >Cancel</Link>
                        <Link className="btn btn-primary" onClick={() => {this.submitLoctionID(rowIndex) }}>Submit</Link>
                    </div>
                    </form>
                </div>
              </div>
          } 

            </div>
          </div>
        </>
      </td>)
    }

    return <td key={index}>{column}</td>;
  };

  updateFlag = (flag, rowIndex) => {
    let request = {
      "accountId": this.state.accounts[rowIndex].id
    }
    if (flag === 'enableCache') {
      if (!this.state.accounts[rowIndex].settings) {
        this.state.accounts[rowIndex].settings = {};
        if (!this.state.accounts[rowIndex].settings?.enableCache) {
          this.state.accounts[rowIndex].settings.enableCache = true;
        } else {
          this.state.accounts[rowIndex].settings.enableCache = !this.state.accounts[rowIndex].settings.enableCache;
        }
      } else {
        this.state.accounts[rowIndex].settings.enableCache = !this.state.accounts[rowIndex].settings.enableCache;
      }
      request['enableCache'] = this.state.accounts[rowIndex].settings?.enableCache;
    } else if (flag === 'enableZingitPersistence') {
      if (!this.state.accounts[rowIndex].settings) {
        this.state.accounts[rowIndex].settings = {};
        if (typeof (this.state.accounts[rowIndex].settings.enableZingitPersistence) === "boolean") {
          this.state.accounts[rowIndex].settings.enableZingitPersistence = {};
        }
        //get pvalue which is true
        if (!this.state.accounts[rowIndex].settings?.enableZingitPersistence.fullPersistence) {
          this.state.accounts[rowIndex].settings.enableZingitPersistence.fullPersistence = true;
        }
        if (this.state.accounts[rowIndex].settings?.enableZingitPersistence.partialPersistence) {
          this.state.accounts[rowIndex].settings.enableZingitPersistence.partialPersistence = !this.state.accounts[rowIndex].settings.enableZingitPersistence.partialPersistence;
        } else {
          this.state.accounts[rowIndex].settings.enableZingitPersistence.fullPersistence = !this.state.accounts[rowIndex].settings.enableZingitPersistence.fullPersistence;
        }
      } else {
        if (typeof (this.state.accounts[rowIndex].settings.enableZingitPersistence) === "boolean" ||
          this.state.accounts[rowIndex].settings.enableZingitPersistence === undefined ||
          this.state.accounts[rowIndex].settings.enableZingitPersistence === null) {
          this.state.accounts[rowIndex].settings.enableZingitPersistence = {};
        }
        //
        if (this.state.accounts[rowIndex].settings?.enableZingitPersistence.partialPersistence) {
          this.state.accounts[rowIndex].settings.enableZingitPersistence.partialPersistence = !this.state.accounts[rowIndex].settings.enableZingitPersistence.partialPersistence;
        } else {
          this.state.accounts[rowIndex].settings.enableZingitPersistence.fullPersistence = !this.state.accounts[rowIndex].settings.enableZingitPersistence.fullPersistence;
        }
      }

      this.state.selectedIndex = rowIndex;
      this.state.selectedDate = this.state.accounts[rowIndex].settings?.enableZingitPersistence?.lastArchiveSyncDate ? new Date(this.state.accounts[rowIndex].settings?.enableZingitPersistence?.lastArchiveSyncDate) : new Date();

      if (this.state.accounts[rowIndex].settings.enableZingitPersistence.fullPersistence) {
        this.state.accounts[rowIndex].settings.enableZingitPersistence.partialPersistence = false;
      }

      request['enableZingitPersistence'] = this.state.accounts[rowIndex].settings?.enableZingitPersistence;

      if (this.state.accounts[rowIndex].settings.enableZingitPersistence.fullPersistence) { this.showModal(this.state.accounts[rowIndex].settings); }
    } else if (flag === 'enableZForms') {
      if (!this.state.accounts[rowIndex].settings) {
        this.state.accounts[rowIndex].settings = {};
        if (!this.state.accounts[rowIndex].settings?.enableZForms) {
          this.state.accounts[rowIndex].settings.enableZForms = true;
        } else {
          this.state.accounts[rowIndex].settings.enableZForms = !this.state.accounts[rowIndex].settings.enableZForms;
        }
      } else {
        this.state.accounts[rowIndex].settings.enableZForms = !this.state.accounts[rowIndex].settings.enableZForms;
      }
      request['enableZForms'] = this.state.accounts[rowIndex].settings?.enableZForms;
    } else if (flag === 'enableZFormsScheduling') {
      if (!this.state.accounts[rowIndex].settings) {
        this.state.accounts[rowIndex].settings = {};
        if (!this.state.accounts[rowIndex].settings?.enableZFormsScheduling) {
          this.state.accounts[rowIndex].settings.enableZFormsScheduling = true;
        } else {
          this.state.accounts[rowIndex].settings.enableZFormsScheduling = !this.state.accounts[rowIndex].settings.enableZFormsScheduling;
        }
      } else {
        this.state.accounts[rowIndex].settings.enableZFormsScheduling = !this.state.accounts[rowIndex].settings.enableZFormsScheduling;
      }
      request['enableZFormsScheduling'] = this.state.accounts[rowIndex].settings?.enableZFormsScheduling;
    } else if (flag === 'enableZSuite') {
      if (!this.state.accounts[rowIndex].settings) {
        this.state.accounts[rowIndex].settings = {};
        if (!this.state.accounts[rowIndex].settings?.enableZSuite) {
          this.state.accounts[rowIndex].settings.enableZSuite = true;
        } else {
          this.state.accounts[rowIndex].settings.enableZSuite = !this.state.accounts[rowIndex].settings.enableZSuite;
        }
      } else {
        this.state.accounts[rowIndex].settings.enableZSuite = !this.state.accounts[rowIndex].settings.enableZSuite;
      }
      request['enableZSuite'] = this.state.accounts[rowIndex].settings?.enableZSuite;
    } else {
      if (!this.state.accounts[rowIndex].settings) {
        this.state.accounts[rowIndex].settings = {};
        if (!this.state.accounts[rowIndex].settings?.enableMissedAppointmentAlerts) {
          this.state.accounts[rowIndex].settings.enableMissedAppointmentAlerts = true;
        } else {
          this.state.accounts[rowIndex].settings.enableMissedAppointmentAlerts = !this.state.accounts[rowIndex].settings.enableMissedAppointmentAlerts;
        }
      } else {
        this.state.accounts[rowIndex].settings.enableMissedAppointmentAlerts = !this.state.accounts[rowIndex].settings.enableMissedAppointmentAlerts;
      }
      request['enableMissedAppointmentAlerts'] = this.state.accounts[rowIndex].settings?.enableMissedAppointmentAlerts;
    }

    Api.coreAPICall(Constants.ADD_UPDATE_SETTINGS, 'POST', request)
      .then((response) => {
        let i = this.state.accounts.findIndex(i => i.id === response.accountId);
        this.state.accounts[i].settings = response;
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    if (this.state.accounts[rowIndex].settings?.enableMissedAppointmentAlerts) {
      let requestKeyword = {
        "intakeQId": this.state.accounts[rowIndex].intakeQId,
        "name": "MissedApptAlert"
      }
      Api.coreAPICall(Constants.CREATE_OR_RETRIEVE_KEYWORD, 'POST', requestKeyword)
        .then((response) => {
          console.log("Keyword creation call completed");
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  showAccountDetail = (column, rowIndex) => {
    console.log("Accc -->" + JSON.stringify(this.state.accounts[rowIndex]));
    Api.call(
      Constants.GET_INTAKEQ_DETAILS +
      `${this.state.accounts[rowIndex].id}`
    )
      .then((response) => {
        this.setState({
          makeActive: rowIndex,
          overlayActive: true,
          disableButton: false,
          currentData: {
            firstname: this.state.accounts[rowIndex].firstName,
            lastname: this.state.accounts[rowIndex].lastName,
            email: this.state.accounts[rowIndex].email,
            company_name: this.state.accounts[rowIndex].companyName,
            notificationemail: response && response.email,
            phone: this.state.accounts[rowIndex].phoneNumber,
            brand_id: this.state.accounts[rowIndex].campaignRegistry != null ? this.state.accounts[rowIndex].campaignRegistry.brandId : "",
            campaign_id: this.state.accounts[rowIndex].campaignRegistry != null ? this.state.accounts[rowIndex].campaignRegistry.campaignId : "",
            address: this.state.accounts[rowIndex].address.addressLine1,
            address_2: this.state.accounts[rowIndex].address.addressLine2,
            state: this.state.accounts[rowIndex].address.stateName,
            city: this.state.accounts[rowIndex].address.city,
            zip: this.state.accounts[rowIndex].address.zip,
            missedApptAlert: this.state.accounts[rowIndex].settings.enableMissedAppointmentAlerts,
            missedApptText: this.state.accounts[rowIndex].settings.missedAppointmentAlertText
          },
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });

  };

  onSearch = (keyword) => {
    this.setState({ keyword: keyword, page: 1 }, this.loadAccounts);
  };

  onHide = () => {
    this.setState({
      makeActive: "---",
      overlayActive: false,
      currentData: [],
      rowSelected: -1,
      disableButton: true,
    });
  };

  save = (e) => {
    const index = this.state.accounts.findIndex(i => i.email === this.state.currentData.email && i.companyName === this.state.currentData.company_name);
    if (e === "save1") {
      Api.coreAPICall(Constants.UPDATE_EMAIL +
        `${this.state.accounts[index].id}&email=${this.state.currentData.notificationemail}`, 'GET')
        .then((response) => {
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
    else {
      let request = {};
      request['accountId'] = this.state.accounts[index].id;
      request['missedAppointmentAlertText'] = this.state.currentData.missedApptText;
      Api.coreAPICall(Constants.UPDATE_ALERT_TEXT, 'POST', request)
        .then((response) => {
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  onChange = (key, value) => {
    if (key === "notificationemail") {
      this.state.currentData.notificationemail = value;
      return;
    }
    else if (key === "missedApptText") {
      this.state.currentData.missedApptText = value;
      this.setState({});
      return;
    }
    let accounts = this.state.accounts;
    let disableButton = true;
    if (key === "username") {
      accounts[this.state.rowSelected].userName = value;
    } else if (key === "password") {
      accounts[this.state.rowSelected].password = value;
    } else if (key === "timezone") {
      accounts[this.state.rowSelected].timezone = value.toString();
    }

    if (
      accounts[this.state.rowSelected].userName &&
      accounts[this.state.rowSelected].userName.length > 0 &&
      accounts[this.state.rowSelected].password &&
      accounts[this.state.rowSelected].password.length > 0 &&
      accounts[this.state.rowSelected].timezone &&
      accounts[this.state.rowSelected].timezone.length > 0
    ) {
      disableButton = false;
    }
    this.setState({ accounts: accounts, disableButton: disableButton });
  };

  skip = () => {
    this.hideModal();
  }

  getNewDate = (date) => {
    this.state.selectedDate = date;
  }

  submit = () => {
    let rowIndex = this.state.selectedIndex;
    let request = {};
    request = this.state.accounts[rowIndex].settings;
    request.enableZingitPersistence.fullPersistence = false;
    request.enableZingitPersistence.partialPersistence = true;
    request.enableZingitPersistence.noPersistence = false;
    request.enableZingitPersistence.lastArchiveSyncDate = this.state.selectedDate;
    Api.coreAPICall(Constants.ADD_UPDATE_SETTINGS, 'POST', request)
      .then((response) => {
        let i = this.state.accounts.findIndex(i => i.id === response.accountId);
        this.state.accounts[i].settings = response;
        this.hideModal();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  /*
  
        page: 1,
        limit: 8,
        total: 0,
        keyword: "",
        loading: true,
        error: null,
        sortBy: "companyName",
        sortDirection: "asc",
        columns: [
          { name: "Account Name" },
          { name: "Contact Number" },
          { name: "Email Address" },
          { name: "ZForms Date" },
          { name: "Persistence" },
          { name: "ZForms" },
          { name: "ZSuite" },
          { name: "Missed Appointment Alerts" }
        ],
        overlayActive: false,
        currentData: [],
        accounts: [],
        persistenceModal: false,
        selectedIndex: -1,
        selectedDate: new Date(),
  */
  render() {
    return (
      <>
        <ThemeHeader
          cardHeader="Accounts"
          onSearch={this.onSearch}
          keyword={this.state.keyword}
          icon={"../../../images/search-icon.svg"}
          page={this.state.page}
          sortBy={this.state.sortBy}
          sortDirection={this.state.sortDirection}
          limit={this.state.limit}
        />

        <Table
          currentPage={this.state.page}
          loading={this.state.loading}
          renderColumn={this.renderColumn}
          renderRow={this.renderRow}
          renderHeaderColumn={this.renderHeaderColumn}
          columns={this.state.columns}
          perPage={this.state.limit}
          sortDirection={this.state.sortDirection}
          total={this.state.total}
          pagination={true}
          onPaginate={this.onPaginate}
          toggleSortDirection={this.toggleSortDirection}
          rows={this.state.accounts}
          onButtonOnePress={this.onShowInvite}
        />

        <Overlay
          active={this.state.overlayActive}
          onHide={this.onHide}
          currentData={this.state.currentData}
          useField={false}
          onChange={this.onChange}
          save={this.save}
        />

        {/* persistence Modal */}
        <Modal
          show={this.state.persistenceModal}
          onHide={() => this.setState({ persistenceModal: false })}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="md"
          className="authModal persistenceModal cardModal"
        >
          <PersistenceModal onClose={this.hideModal} onSkip={this.skip} onSubmit={this.submit} onChangeDate={this.getNewDate} lastSyncDate={this.state.selectedDate} />
        </Modal>

      </>
    );
  }
}

export default AllAccounts;