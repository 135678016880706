import React, {Component} from 'react';
import {Switch, BrowserRouter} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainRoute from "./views/components/MainRoute";
import AuthRoute from "./views/components/AuthRoute";
import Login from "./views/auth/login/Login";
import AllAccounts from "./views/main/allAccounts/AllAccounts";
import Requests from "./views/main/requests/Requests";
import RequestsRoute from "./views/components/RequestsRoute";
import LoaStatus from "./views/main/loaStatus/LoaStatus";
import UpgradePlan from "./views/main/upgradePlan/UpgradePlan";
import OSAdmin from './views/main/OSAdmin/OSAdmin';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: true
    }
  }

  render() {
    if (!this.state.ready) {
      return <div/>;
    }
    return (
        <BrowserRouter>
          <Switch>
            <MainRoute path="/allAccounts" name="allAccounts" component={AllAccounts}/>
            <RequestsRoute path="/requests" name="requests" component={Requests}/>
            <RequestsRoute path="/loaStatus" name="loaStatus" component={LoaStatus}/>
            <RequestsRoute path="/upgradePlan" name="upgradePlan" component={UpgradePlan}/>
            <OSAdmin path="/OSAdmin" name="OSAdmin" component={OSAdmin} />
            <AuthRoute path="/" name="login" component={Login}/>
          </Switch>
          <ToastContainer theme="colored"/>
        </BrowserRouter>
    );
  }
}

export default App;
