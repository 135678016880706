import React, { Component } from "react";
import { Form } from "react-bootstrap";
import DefaultButton from "../../components/buttons/DefaultButton";
import Col from "react-bootstrap/Col";
import FormInput from "../../components/form/FormInput";
import { Link } from "react-router-dom";
import Api from "../../../api/Api";
import Constants from "../../../utils/Constants";
import LoginHeader from "../../components/Loginheader/LoginHeader";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      active: true,
      loading: false,
      email: "",
      password: "",
      error: null,
    };
  }

  async componentDidMount() {
    localStorage.clear();
    if(window.location.href.includes("token")) {
      this.setState({ssoloading: true});
      let params = window.location.search.substr(1);
      let token = params.substring(params.indexOf("=") + 1);
      if (token) {
        Api.call("sso/validate?token=" + token, "GET")
        .then(async (response) => {
          localStorage.setItem("cognitoSSOToken", token);
          this.setState({ username: response.userName, password: response.password});
          Api.call("accountLogin", "POST", { username: response.userName, password: response.password})
          .then(async (response) => {
            this.generateKey();
          })
          .catch((error) => {
            this.setState({ error: "Invalid Username and Password", ssoloading: false});
          })
        })
        .catch((error) => {
          this.setState({ loading: false, ssoloading: false });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
      }
    }
  }

  login = () => {
    if(!this.state.email) {
      this.setState({ error: "Invalid Email & Password" });
      return;
    }

    if(!this.state.password) {
      this.setState({ error: "Invalid Password" });
      return;
    }
    this.setState({ loading: true, error: null });
    let data = {
      username: this.state.email,
      password: this.state.password,
    };

    Api.call("accountLogin", "POST", data)
    .then(async (response) => {
      localStorage.setItem('sessionKey',response.sessionKey)
      if(response.name==='Error') throw new Error();
      this.generateKey();
    })
    .catch((error) => {
      this.setState({ error: "Invalid Username or Password" });
    })
    .finally(() => {
      this.setState({ loading: false });
    });
  };

  generateKey = () => {
    Api.call(Constants.GENERATE_KEY + "projmgmt@zingitsolutions.com")
    .then((response) => {
      if (response.status === "success") {
        localStorage.setItem("AMT_ZINGIT_AUTH_EMAIL", response.data.email);
        localStorage.setItem(
          "AMT_ZINGIT_AUTH_SECRET",
          response.data.secretKey
        );
        this.props.history.push("/allAccounts");
      } else {
        this.setState({ error: "Invalid Email & Password" });
      }
    })
    .catch((error) => {
      this.setState({ error: error });
    })
    .finally((_) => {
      this.setState({ loading: false });
    });
  }

  render() {
    if (this.state.ssoloading) {
      return (
        <div className="">
          <div className="">
            {" "}
            <div className="defalut-loader-screen">
              <img src='../../../../images/loader-new.gif' alt="Loader" />
            </div>
          </div>
        </div>
      )
    }
    if(!this.state.ssoloading) {
      return (
        <>
          <LoginHeader />
          <div className="auth-screen">
            <div className="container login-mainpage">
              <div className="row">
                <div className="col-sm-12 col-md-5 login-form p-2">
                  <div className="auth-overlay">
                    <div className="auth-form">
                      <div className="d-flex text-center flex-column ">
                        <div className="zingit-logo"><img alt="logo" src='../../../../images/Zingit-logo.svg' /></div>
                        <h5>Welcome Back</h5>
                      </div>
                      <Form>
                        <div className="login-credential">
                          <Form.Row className="mb-3">
                            <Col md={12}>
                              <FormInput
                                placeholder={"Email Address"}
                                value={this.state.email}
                                onChange={(e) => {
                                  this.setState({ email: e.target.value });
                                }}
                                label="Email:"
                              />
                            </Col>
                            <Col md={12}>
                              <FormInput
                                type={"password"}
                                placeholder={"Password"}
                                value={this.state.password}
                                onChange={(e) => {
                                  this.setState({ password: e.target.value });
                                }}
                                label="Password:"
                              />
                            </Col>
                          </Form.Row>
                          <div className="position-relative">
                            {this.state.error && (
                              <div className="form-error">{this.state.error}</div>
                            )}
                            <DefaultButton
                              name="Login"
                              onClick={this.login}
                              loading={this.state.loading}
                              className="default-login-button full"
                            />
                          </div>
                          <div className="w-100 d-flex justify-content-center pt-2">
                            <Link to="/" className="forgot">
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-7 login-text">
                  <div className="p-2">
                    <div class="signin-articles">
                      <ul class="list-unstyled">
                        <li>
                          <a class="article-title">Accounts Repository</a>
                          <p>
                            Account Management Tool is a repository for all the
                            Zingit accounts
                          </p>
                          <a class="article-title">
                            {" "}
                            Create an account in the Zingit Platform{" "}
                          </a>
                          <p>
                            Pre filled details in the Account Management Tool
                            allows you to create an account in the Zingit Platform
                            with ease{" "}
                          </p>
                          <a class="article-title"> Move to 10DLC </a>

                          <p>
                            With the Account Management Tool, you can move an
                            office from short code to their business telephone
                            number
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default Login;
