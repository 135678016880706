import React, { Component } from "react";
import { Form } from "react-bootstrap";
import FormInput from "../../components/form/FormInput";
import Col from "react-bootstrap/Col";

const fields = {
  username: {
    label: "Username*",
    type: "text",
    defaultValue: "",
    required: true,
    errorMessage: "Username is required",
  },
  password: {
    label: "Password*",
    type: "password",
    defaultValue: "",
    required: true,
    errorMessage: "Password is required",
  },
  timezone: {
    label: "Time Zone*",
    type: "text",
    renderAs: "select",
    options: [
      { label: "", value: "" },
      { label: "Hawaii-Aleutian", value: -2 },
      { label: "Alaska", value: -1 },
      { label: "Pacific Time", value: 0 },
      { label: "Mountain Time", value: 1 },
      { label: "Central Time", value: 2 },
      { label: "Eastern Time", value: 3 },
      { label: "Atlantic Time", value: 4 },
    ],
    defaultValue: 0,
    required: true,
    md: 12,
    errorMessage: "Time Zone is required",
  },
  firstname: {
    label: "First Name",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  lastname: {
    label: "Last Name",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  email: {
    label: "Email Address",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
    md: 12,
  },
  notificationemail: {
    label: "Z-form Notification Email",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
    md: 12,
    buttoncancel:"Cancel",
    buttonsave: "Save",
    editicon:'../../../../images/edit-chat.svg'
  },
  company_name: {
    label: "Company Name",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  phone: {
    label: "Phone Number",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  address: {
    label: "Address 1",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
    md: 12,
  },
  address_2: {
    label: "Address 2",
    type: "text",
    defaultValue: "",
    required: false,
    readOnly: true,
    md: 12,
  },
  city: {
    label: "City",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  zip: {
    label: "Zip Code",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  state: {
    label: "State",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
    md: 12,
  },
};

let fields2 = {
  // username: {
  //   label: "Username",
  //   type: "text",
  //   defaultValue: "",
  //   required: true,
  //   readOnly: true,
  // },
  // password: {
  //   label: "Password",
  //   type: "password",
  //   defaultValue: "",
  //   required: true,
  //   readOnly: true,
  // },
  // timezone: {
  //   label: "Time Zone",
  //   type: "text",
  //   defaultValue: "",
  //   required: true,
  //   readOnly: true,
  //   md: 12,
  // },
  firstname: {
    label: "First Name",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  lastname: {
    label: "Last Name",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  email: {
    label: "Email Address",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
    md: 12,
  },
  notificationemail: {
    label: "Z-form Notification Email",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
    md: 12,
    buttoncancel:"Cancel",
    buttonsave: "Save",
    editicon:'../../../../images/edit-chat.svg'
  },
  company_name: {
    label: "Company Name",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  phone: {
    label: "Phone Number",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  brand_id: {
    label: "Brand Id",
    type: "text",
    defaultValue: "",
    required: false,
    readOnly: true,
  },
  campaign_id: {
    label: "Campaign Id",
    type: "text",
    defaultValue: "",
    required: false,
    readOnly: true,
  },
  address: {
    label: "Address 1",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
    md: 12,
  },
  address_2: {
    label: "Address 2",
    type: "text",
    defaultValue: "",
    required: false,
    readOnly: true,
    md: 12,
  },
  city: {
    label: "City",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  zip: {
    label: "Zip Code",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
  },
  state: {
    label: "State",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
    md: 12,
  },
  missedApptText: {
    label: "Missed Appointment Template",
    type: "text",
    defaultValue: "",
    required: true,
    readOnly: true,
    md: 12,
    buttoncancel:"Cancel",
    buttonsave: "Save",
    editicon:'../../../../images/edit-chat.svg'
  },
};

let initialAccount = {
  source_acct_guid: "{1D8F07E3-855A-445C-AAC8-DC6F1633DBD1}",
};

Object.keys(fields).map((key) => {
  if (fields[key].defaultValue) {
    initialAccount[key] = fields[key].defaultValue;
  }
  return null;
});

Object.keys(fields2).map((key) => {
  if (fields2[key].defaultValue) {
    initialAccount[key] = fields2[key].defaultValue;
  }
  return null;
});

class Overlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: initialAccount,
      errors: {},
      missedApptReadOnly: true,
      clickEditIcon: false,
    };
  }

  toggleClickEditIcon = () => {
    this.setState({clickEditIcon: !this.state.clickEditIcon});
  }

  toggleMissedApptReadonly = (isReadOnly) => {
    this.setState({missedApptReadOnly: isReadOnly});
  }

  onHideOverlay = () => {
    this.setState({clickEditIcon: false,missedApptReadOnly: true});
    this.props.onHide();
  }

  render() {
    return (
      <div id="overlay" className={this.props.active ? "" : "overlayhide"}>
        <div className="form-wrapper">
          <div className="form-header">
            <div className="header-text row">
              <span className="align-middle col-6">
                {this.props.currentData.company_name
                  ? this.props.currentData.company_name
                  : ""}
              </span>
              <a
                className="col-6 close-image"
                onClick={this.onHideOverlay}
              >
                <img src="../../../../images/close-button.svg" />
              </a>
            </div>
          </div>
          <div className="container form-body">
            <Form>
              {this.props.useField ? (
                <Form.Row>
                  {Object.keys(fields).map((key) => {
                    return (
                      <Col key={key} md={fields[key].md ? fields[key].md : 6}>
                        <FormInput 
                          error={this.state.errors[key]}
                          label={fields[key].label}
                          value={
                            this.props.currentData
                              ? this.props.currentData[key]
                              : ""
                          }
                          renderAs={fields[key].renderAs}
                          options={fields[key].options}
                          readOnly={fields[key].readOnly}
                          onChange={(e) => {
                            this.props.onChange(key, e.target.value);
                          }}
                        />
                      </Col>
                    );
                  })}
                </Form.Row>
              ) : (
                <Form.Row>
                  {Object.keys(fields2).map((key) => {
                    return (
                      <Col key={key} md={fields2[key].md ? fields2[key].md : 6}>
                        <FormInput
                          id={key}
                          error={this.state.errors[key]}
                          label={fields2[key].label}
                          value={
                            this.props.currentData
                              ? this.props.currentData[key]
                              : ""
                          }
                          missedApptAlert={this.props.currentData['missedApptAlert']}
                          missedApptAlertText={this.props.currentData['missedApptText']}
                          renderAs={fields2[key].renderAs}
                          options={fields2[key].options}
                          readOnly={fields2[key].readOnly}
                          editicon={fields2[key].editicon}
                          buttoncancel={fields2[key].buttoncancel}
                          buttonsave={fields2[key].buttonsave}
                          onChange={(e) => {
                            this.props.onChange(key, e.target.value);
                          }}
                          save={(e) => {
                            this.props.save(e);
                          }}
                          toggleClickEditIcon={this.toggleClickEditIcon}
                          clicked={this.state.clickEditIcon}
                          toggleMissedApptReadonly={this.toggleMissedApptReadonly}
                          missedApptReadOnly={this.state.missedApptReadOnly}
                        />
                      </Col>
                    );
                  })}
                </Form.Row>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default Overlay;
