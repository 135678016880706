import React, { Component } from "react";
import { Form } from "react-bootstrap";
import FormSelectbox from "./FormSelectbox";
import classNames from "classnames";

class FormInput extends Component {
  constructor(props){
    super(props);
    this.state={
      missedApptText: "",
      isEditicon1: false,
      isEditicon2: false,
    }
  }

  handleClick = (e) => {
    const data = e.target.dataset.type;
    if(data === 'editicon1') this.setState({isEditicon1: true});
    if(data === 'editicon2') this.setState({isEditicon2: true});
    if(data === 'cancel-btn1') this.setState({isEditicon1: false});
    if(data === 'cancel-btn2') this.setState({isEditicon2: false});

    this.props.toggleClickEditIcon();
    if(this.props.label === "Missed Appointment Template") {
      (data === 'editicon2') ? this.props.toggleMissedApptReadonly(false) : this.props.toggleMissedApptReadonly(true);
    }
  }

  saveEmail = (e) => {
    this.props.toggleClickEditIcon();
    this.setState({isEditicon1: false,isEditicon2: false});
    this.props.toggleMissedApptReadonly(true);
    if(e.target.dataset.type === 'save1') 
      this.props.save(e.target.dataset.type); 
    else 
      this.props.save(this.state.missedApptText);
  }

  handleTextarea = (e) => {
    this.setState({missedApptText: e.target.value});
  }

  render() {
    const url = window.location.pathname;
    const isLoa = url?.includes('/requests/loaStatus');
    const isIvision = url?.includes('/requests/accountCreation');

    const props=this.props;
    const id = (props.id === 'notificationemail') ? '1' : (props.id === 'missedApptText') ? '2' : '';
    if (props.readOnly) {
      return (
        <Form.Group controlId="formGroupEmail" className={props.class}>
        {((isLoa || isIvision) && props.label==="Z-form Notification Email")?<></>
        :<>
        <Form.Label>{props.label}</Form.Label>
           
           <div className={`overlay-forms-field ${props.label === "Missed Appointment Template"?"missedAppt":""}`} style={{padding:"0px"}}>
           {
               (props.label==="Z-form Notification Email" && this.props.clicked && this.state.isEditicon1)?
               <Form.Control style={{borderLeftWidth:"0px",borderRightWidth:"0px"}}
               className={classNames(props.error && "error")}
               type={props.type}
               defaultValue={props.value}
               onChange={props.onChange}
               placeholder={props.placeholder}
               required="required"
               />
               :
               (props.label === "Missed Appointment Template")?
               <Form.Control
                as="textarea" rows={4}
                className="missedAppt-textarea"
                value={props.value}
                onChange={props.onChange}
                readOnly={this.props.missedApptReadOnly}
                />
               :
               <span className="input-group-text background-grey">
               {props.value}
               </span>
             }
             
            {props.editicon && (
              <>
                <img src={props.editicon} alt="icon" data-type={`editicon${id}`}
                onClick={this.handleClick} className={`${this.props.clicked || (props.label === "Missed Appointment Template" && !this.props.missedApptAlert)?"hide":"edit-icon"}`} />
              </>)}
           </div>
           
           <div>
            {props.buttoncancel && this.props.clicked && (
              <div className={`notification-buttons ${this.state.isEditicon1 ? "" : this.state.isEditicon2 ? "missedAppt" : "hide"}`} data-type={`notify-btn${id}`}>
                <button className="overlay-cancel" type="button" data-type={`cancel-btn${id}`} onClick={this.handleClick} >{props.buttoncancel}</button>
                <button className="overlay-Save" type="button" data-type={`save${id}`} onClick={this.saveEmail} >{props.buttonsave}</button>
              </div>)}
           </div>
        </>}
          
        </Form.Group>
      );
    }

    if (props.renderAs === "select") {
      return (
        <FormSelectbox
          label={props.label}
          options={props.options}
          value={props.value}
          onChange={props.onChange}
          error={props.error}
        />
      );
    }

    return (
      <Form.Group controlId="formGroupEmail" className={props.class}>
        <Form.Label>{props.label}</Form.Label>

        <Form.Control
          className={classNames(props.error && "error")}
          type={props.type}
          value={this.props.value}
          onChange={this.props.onChange}
          placeholder={props.placeholder}
          required="required"
        />
        <span className="err-input">{props.error}</span>
      </Form.Group>
    );
  }
}

export default FormInput;