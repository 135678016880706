import React, { Component } from "react";
import Pagination from "../Paginaton/Pagination";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortDirection: "asc",
      sortBy: "companyName",
    };
  }

  renderRow = (row, index) => {
    let columnIndex = 0;
    return (
      <tr>
        {row.map((column) => {
          return this.renderColumn(column, columnIndex++);
        })}
      </tr>
    );
  };

  renderHeader = (columns) => {
    let columnIndex = 0;
    return columns.map((column) => {
      return this.renderHeaderColumn(column, columnIndex++);
    });
  };

  renderColumn = (column, index) => {
    return <td>{column}</td>;
  };

  renderHeaderColumn = (column, index) => {
    return (
      <th>
        {column.name}
        {index === 0 && this.state.sortDirection === "asc" ? (
          <img
            alt="sort-up"
            className="sort-up"
            src="../../../../images/sort-up-solid.svg"
            onClick={() => this.toggleSortDirection("companyName")}
          />
        ) : (
          ""
        )}
        {index === 3 && this.state.sortDirection === "asc" ? (
          <img
            alt="sort-up"
            className="sort-up"
            src="../../../../images/sort-up-solid.svg"
            onClick={() => this.toggleSortDirection("enableZFormsDate")}
          />
        ) : (
          ""
        )}
        {index === 0 && this.state.sortDirection !== "asc" ? (
          <img
          alt="caret-down"
            src="../../../../images/caret-down-solid.svg"
            onClick={() => this.toggleSortDirection("companyName")}
          />
        ) : (
          ""
        )}
        {index === 3 && this.state.sortDirection !== "asc" ? (
          <img
            alt="caret-down"
            src="../../../../images/caret-down-solid.svg"
            onClick={() => this.toggleSortDirection("enableZFormsDate")}
          />
        ) : (
          ""
        )}
      </th>
    );
  };

  toggleSortDirection = (sortBy) => {
    if (this.state.sortDirection === "asc") {
      this.setState({ sortDirection: "desc" });
    } else {
      this.setState({ sortDirection: "asc" });
    }
    this.setState({ sortBy });
    this.props.toggleSortDirection(this.state.sortDirection, sortBy);
  };

  renderLoading = () => {
    return (
      <div
        className={`table-responsive loading-bar ${this.props.loadingClass}`}
      >
        <img alt="loading" width={50} src="../../../../images/loading.gif" />
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return this.renderLoading();
    }

    let rowIndex = 0;

    let props = this.props;
    if (props.renderRow) {
      this.renderRow = props.renderRow;
    }
    if (props.renderColumn) {
      this.renderColumn = props.renderColumn;
    }
    if (props.renderHeaderColumn) {
      this.renderHeaderColumn = props.renderHeaderColumn;
    }

    return (
      <div className="table-responsive ">
        <table className="table">
          <thead>
            <tr className="th-bold">{this.renderHeader(props.columns)}</tr>
          </thead>
          {this.props.total == 0 ? (
            <tbody>
              <tr>
                <td colSpan="100%" className="norecords">
                  No accounts found
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {props.rows.map((row) => {
                return this.renderRow(row, rowIndex++);
              })}
            </tbody>
          )}
        </table>
        <div className={this.props.cardFooter}>
          <div className="float-right">
            {props.pagination && (
              <Pagination
                totalItems={this.props.total}
                currentPage={this.props.currentPage}
                perPage={this.props.perPage}
                onPaginate={props.onPaginate}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Table;
