import React, {Component} from 'react';

class FromSwitch extends Component {

    render() {

        return (
            <label className="switch">
                <input type="checkbox" checked={this.props.checked} onChange={this.props.onChange}/>
                <span className="slider round"></span>
                <span className="onn">ON</span>
                <span className="off">Off</span>
            </label>
        );
    }
}

export default FromSwitch;
