import React from 'react'
import {Route} from 'react-router-dom';
import MainHeader from "./Header/MainHeader";
import Sidebar from "./sidebar/Sidebar";
import AccountCreation from "../main/accountCreation/AccountCreation";
import LoaStatus from '../main/loaStatus/LoaStatus';
import UpgradePlan from '../main/upgradePlan/UpgradePlan';

const RequestsRoute = ({component: Component}) => {

    return (
        <Route
            render={props => (
                <div className="content-container">
                    {/* Header */}
                    <MainHeader/>

                    {/* Sidebar */}
                    <Sidebar active={'requests'}/>
                    <div className="screens">
                        <Route exact path="/requests/accountcreation" component={AccountCreation}/>
                        <Route exact path="/requests/loaStatus" component={LoaStatus}/>
                        <Route exact path="/requests/upgradePlan" component={UpgradePlan}/>
                    </div>
                </div>
            )}
        />
    )
};

export default RequestsRoute;
