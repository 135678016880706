import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";

import {Link} from "react-router-dom";
class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  logout=()=>{
    localStorage.clear();
  }
  
  toggleClass = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };

  render() {
    let url = window.location.pathname;
    return (
      <header
        className={this.state.active ? "mainheader show" : "mainheader show"}
      >
        <div className="pcMenu">
          <Navbar
            bg="transparent"
            variant="light"
            className="justify-content-between px-0 "
          >
            <div className="all-screens-head d-flex align-items-center">
              <img
                className="zingitLogo"
                src="../../../../images/plan-zingit.svg"
                alt="logo"
              />
              <span class="screen-name">AMT</span>
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex" id="navbarSupportedContent">
              <li className={`nav-item ${
                    url.includes("/osAdmin") ? "active" : ""
                  }`} >
                  <Link className="nav-link" to="/osAdmin">OS ADMIN</Link>
                </li>
                <li
                  className={`nav-item ${
                    url.includes("/allAccounts") ? "active" : ""
                  }`}
                >
                  <Link className="nav-link" to="/allAccounts">
                    ALL ACCOUNTS
                  </Link>
                </li>
              </div>

              <NavDropdown
                className={`nav-item ${
                  url.includes("/requests") ? "active" : ""
                }`}
                title={"ACCOUNT PENDING"}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  className={`nav-item drop-down-item ${
                    url.includes("/requests/accountCreation") ? "active" : ""
                  }`}
                  href="/requests/accountCreation"
                >
                  <img
                    className="accountcreationLogo"
                    src="../../../../images/user-solid.svg"
                    alt="logo"
                  />
                  Ivision Account Creation
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={`nav-item drop-down-item ${
                    url.includes("/requests/loaStatus") ? "active" : ""
                  }`}
                  href="/requests/loaStatus"
                >
                  <img
                    className="accountcreationLogo"
                    src="../../../../images/cogs-solid.svg"
                    alt="logo"
                  />
                  LOA Status
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="/requests/upgradePlan">
                  Upgrade Zingit Plan
                </NavDropdown.Item> */}
              </NavDropdown>

              <div class="d-flex p-0" id="navbarSupportedContent">
                <li class="nav-item">
                  <a class="logout-icon"onClick={this.logout} href="/">
                    <img
                      className="thumbnail-image"
                      width="20"
                      src="../../../../images/logout-svgrepo-com.svg"
                      alt="user pic"
                    />
                  </a>
                </li>
              </div>
            </div>
          </Navbar>
        </div>
      </header>
    );
  }
}

export default MainHeader;
