import React, {Component} from 'react';
import ThemeHeader from "../../components/themeHeading/ThemeHeader";
import Table from "../../components/Table/Table";
import Api from "../../../api/Api";
import DefaultButton from "../../components/buttons/DefaultButton";
import ThemeModal from "../../components/modal/ThemeModal";
import ThemeModalLoading from "../../components/modal/ThemeModalLoading";


class Requests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            default:false,
            number:'',account:'',
            page: 1,
            limit: 20,
            total:0,
            keyword : '',
            loading: true,
            error: null,
            showModal: false,
            showLoadingModal: false,
            showSuccessModal: false,
            columns: [
                {name: "#"},
                {name: "Account",},
                {name: "Phone",},
                {name: "Created At",},
                {name: "Status",},
                {name: "Actions",},
            ],
            requests: [
            ]
        }
    }

    initialState = ()=>{
        return {default:false,number:'',account:''};
    };

    componentDidMount() {
        this.loadAccounts();
    }

    onPaginate = (page)=>{
        this.setState({page:page},this.loadAccounts)
    };

    loadAccounts = ()=>{
        Api.call(`/accounts/numbers/import/requests`).then(requests=>{
            this.setState({requests:requests});
        }).finally(()=>{
            this.setState({loading:false});
        })
    };
    parseRow = (row,index)=>{
        return [index + 1, row.account, row.phone,row.createdAt, row.status === 0 ? "Pending" : "Processed", ""];
    };

    renderColumn = (column, index, rowIndex, row) => {
        if (index === row.length - 1 && this.state.requests[rowIndex].status === 0) {
            return <td key={index}>
                <ul>
                    <li>
                        <DefaultButton className="default-button" name="Import"
                                       onClick={() => {
                                           this.onShowModal(this.state.requests[rowIndex].phone,this.state.requests[rowIndex].account)
                                       }}/>
                    </li>
                </ul>
            </td>
        }
        return <td key={index}>{column}</td>
    };

    addNumber = (number, setDefault) => {
        let account = this.state.account;
        this.setState({showModal: false, showLoadingModal: true});
        Api.call("/accounts/numbers/import", "POST", {guid: this.state.guid, number: number,account:account, setDefault: setDefault})
            .then(response => {
                this.setState({loadingNumbers: true, showLoadingModal: false, showSuccessModal: true});
                this.loadAccounts();
            }).catch(error => {
            this.setState({showLoadingModal: false, showErrorModal: true, importNumberError: error});
        })
    };

    onShowModal = (number,account) => {
        this.setState({number: number, account:account,default:false, showModal: true});
    };

    onCloseModal = () => {
        this.setState({showModal: false});
    };


    renderRow = (row, index) => {
        let columnIndex = 0;
        row = this.parseRow(row, index);
        return <tr key={index}>
            {row.map(column => {
                return this.renderColumn(column, columnIndex++, index, row);
            })}</tr>
    };


    onSearch = (keyword)=>{
      this.setState({keyword:keyword},this.loadAccounts);
    };

    render() {
        return (
            <>
                <ThemeHeader icon={'../../../images/list.png'} cardHeader="Account Pending"  onSearch={this.onSearch} keyword={this.state.keyword}
                             link="/account/create-account" class="blue-button"/>

                <Table currentPage={this.state.page} loading={this.state.loading}
                       renderColumn={this.renderColumn}
                       renderRow={this.renderRow}
                       renderHeaderColumn={this.renderHeaderColumn}
                       columns={this.state.columns} perPage={this.state.limit}
                       total={this.state.total}
                       pagination={false}
                       onPaginate={this.onPaginate}
                       rows={this.state.requests} onButtonOnePress={this.onShowInvite}/>
                <ThemeModal onSubmit={this.addNumber} number={this.state.number} showModal={this.state.showModal}
                            onChange={(e) => {
                                this.setState({number: e.target.value});
                            }} onClose={this.onCloseModal}/>

                <ThemeModalLoading type={'loading'} showModal={this.state.showLoadingModal}/>
                <ThemeModalLoading type={'success'} showModal={this.state.showSuccessModal} onClose={() => {
                    this.setState({showSuccessModal: false})
                }}/>
                <ThemeModalLoading type={'error'} error={this.state.importNumberError}
                                   showModal={this.state.showErrorModal} onClose={() => {
                    this.setState({showErrorModal: false})
                }}/>
            </>
        );
    }
}

export default Requests;
