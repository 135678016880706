import React, { Component } from "react";
import ThemeHeader from "../../components/themeHeading/ThemeHeader";
import Table from "../../components/Table/Table";
import Api from "../../../api/Api";
import Constants from "../../../utils/Constants";

class UpgradePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 8,
      total: 0,
      keyword: "",
      loading: true,
      error: null,
      columns: [
        { name: "Account Name" },
        { name: "Contact Number" },
        { name: "Email Address" },
      ],
      accounts: [],
    };
  }

  componentDidMount() {
    this.loadAccounts();
  }

  onPaginate = (page) => {
    this.setState({ page: page }, this.loadAccounts);
  };

  loadAccounts = () => {
    Api.call(
      Constants.GET_ACCOUNTS_LIST +
        `${this.state.keyword.length === 0 ? " " : this.state.keyword}&limit=${
          this.state.limit
        }&page=${this.state.page}&onlyForAmt=1`
    )
      .then((accounts) => {
        this.setState({
          accounts: accounts.data.data,
          total: accounts.data.totalRecords,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  parseRow = (row, index) => {
    return [row.companyName, row.phoneNumber, row.email];
  };
  renderRow = (row, index) => {
    row = this.parseRow(row, index);
    let columnIndex = 0;
    return (
      <tr key={row.id}>
        {row.map((column) => {
          return this.renderColumn(column, columnIndex++, index, row);
        })}
      </tr>
    );
  };
  renderColumn = (column, index) => {
    return <td key={index}>{column}</td>;
  };

 

  render() {
    return (
      <>
        <ThemeHeader
          cardHeader="Upgrade Zingit Plan"
          onSearch={this.onSearch}
          keyword={this.state.keyword}
          button="Upgrade to PRO"
          class={
            this.state.disableButton
              ? "orange-button "
              : "orange-button "
          }
        />

        <Table
          currentPage={this.state.page}
          loading={this.state.loading}
          renderColumn={this.renderColumn}
          renderRow={this.renderRow}
          renderHeaderColumn={this.renderHeaderColumn}
          columns={this.state.columns}
          perPage={this.state.limit}
          total={this.state.total}
          pagination={true}
          onPaginate={this.onPaginate}
          rows={this.state.accounts}
          onButtonOnePress={this.onShowInvite}
        />
      </>
    );
  }
}

export default UpgradePlan;
