import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import DefaultButton from "../buttons/DefaultButton";

const PersistenceModal = (props) => {
    let [startDate, setStartDate] = useState(new Date(props.lastSyncDate));
    
    const setDate = (date) => {
        setStartDate(date);
        props.onChangeDate(date);
    }

    return (

        <div className="dark d-flex flex-column justify-content-between align-items-center ">
            <div className="modal-header opt-header1 border-0  ">
                <h4 className="opt-header " >Your last sync date is</h4>
            </div>
            <div className="close-icon" onClick={props.onClose}>
                <img alt="closed" src="../../../../images/closed.svg" />
            </div>
            <div className="datepicker-new">
                <DatePicker selected={startDate} onChange={(date) => setDate(date)} />
            </div>
            <div className="modal-footer border-0 ">
                <DefaultButton className="skipbutton mr-2" name="Skip" onClick={props.onSkip} />
                <DefaultButton className="blue-button-new " name="Submit" onClick={props.onSubmit} />
            </div>
        </div>

    );
}

export default PersistenceModal;
