import React, { Component } from "react";
import { Card, Form } from "react-bootstrap";
import ThemeHeader from "../../components/themeHeading/ThemeHeader";
import FormInput from "../../components/form/FormInput";
import Col from "react-bootstrap/Col";
import Table from "../../components/Table/Table";
import DefaultButton from "../../components/buttons/DefaultButton";
import ThemeModal from "../../components/modal/ThemeModal";
import FromSwitch from "../../components/form/FromSwitch";
import qs from "qs";
import Api from "../../../api/Api";
import ThemeModalLoading from "../../components/modal/ThemeModalLoading";
import { confirmAlert } from "react-confirm-alert";

const fieldTitles = {
  firstname: "First Name",
  lastname: "Last Name",
  email: "Email",
  notificationemail: "Z-form Notification Email",
  company_name: "Company Name",
  address: "Address",
  //city_id: "City",
  city: "City",
  //state_id: "State",
  //country_id: "Country",
  zip: "Zip Code",
  username: "Username",
  //password: "Password",
  phone: "Phone",
  //phone_ext: "Phone Ext",
  //fax: "Fax",
  //preferred_contact: "Preferred Contact",
  timezone_label: "Time Zone",
  //timezone_label_sm_DST: "Time Zone",
  internal_id: "Account Number",
  status: "Status",
  //mobile: "Mobile",
};

class AccountDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: "",
      showModal: false,
      showLoadingModal: false,
      showErrorModal: false,
      showSuccessModal: false,
      account: null,
      loadingNumbers: true,
      loadingRequests: true,
      loadingKeywords: true,
      loading: true,
      guid: null,
      columns: [
        { name: "#" },
        { name: "Phone" },
        { name: "Default" },
        { name: "Active" },
        { name: "Carrier" },
        { name: "LOA Signed" },
        { name: "Action" },
      ],
      columns1: [
        { name: "#" },
        { name: "Phone" },
        { name: "Created At" },
        { name: "Status" },
        { name: "Action" },
      ],
      columns2: [
        { name: "#" },
        { name: "Keyword" },
        { name: "Phone" },
        { name: "Status" },
      ],
      lists: [
        ["(878) 324 3243", "(878) 324 3243", "Active", ""],
        ["(878) 324 3243", "(878) 324 3243", "Active", ""],
      ],
      numbers: [],
      requests: [],
      keywords: [],
    };
  }

  componentDidMount() {
    let { guid } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState({ guid: guid }, this.loadNumbers);
    Api.call(`/accounts/details?guid=${encodeURI(guid)}`)
      .then((account) => {
        this.setState({ account: account }, () => {
          this.loadRequests();
          this.loadKeywords();
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  loadNumbers = () => {
    Api.call(`/accounts/numbers?guid=${encodeURI(this.state.guid)}`)
      .then((numbers) => {
        this.setState({ numbers: numbers });
      })
      .finally(() => {
        this.setState({ loadingNumbers: false });
      });
  };

  loadRequests = () => {
    this.setState({ loadingRequests: true });
    Api.call(
      `/accounts/numbers/import/requests?account=${this.state.account.internal_id}`
    )
      .then((requests) => {
        this.setState({ requests: requests });
      })
      .finally(() => {
        this.setState({ loadingRequests: false });
      });
  };
  loadKeywords = () => {
    this.setState({ loadingKeywords: true });
    Api.call(`/accounts/keywords?guid=${encodeURI(this.state.guid)}`)
      .then((keywords) => {
        this.setState({ keywords: keywords });
      })
      .finally(() => {
        this.setState({ loadingKeywords: false });
      });
  };

  onShowModal = (number) => {
    this.setState({ number: number, showModal: true });
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  parseRow = (row, index) => {
    return [
      index + 1,
      row.phone_number,
      row.default_flag,
      row.active_flag ? "Active" : "Not Active",
      row.aggr_name,
      row.loa_signed ? "Yes" : "No",
      "",
    ];
  };

  parseRow1 = (row, index) => {
    return [
      index + 1,
      row.phone,
      row.createdAt,
      row.status === 0 ? "Pending" : "Processed",
      "",
    ];
  };

  parseRowKeywords = (row, index) => {
    return [
      index + 1,
      row.keyword,
      row.shortcode,
      row.active ? "Active" : "Not Active",
    ];
  };

  renderRow = (row, index) => {
    let columnIndex = 0;
    row = this.parseRow(row, index);
    return (
      <tr key={index}>
        {row.map((column) => {
          return this.renderColumn(column, columnIndex++, index, row);
        })}
      </tr>
    );
  };
  renderRowRequests = (row, index) => {
    let columnIndex = 0;
    row = this.parseRow1(row, index);
    return (
      <tr key={index}>
        {row.map((column) => {
          return this.renderColumnRequests(column, columnIndex++, index, row);
        })}
      </tr>
    );
  };
  renderRowKeywords = (row, index) => {
    let columnIndex = 0;
    row = this.parseRowKeywords(row, index);
    return (
      <tr key={index}>
        {row.map((column) => {
          return this.renderColumnKeywords(column, columnIndex++, index, row);
        })}
      </tr>
    );
  };

  setDefaultNumber = (number) => {
    confirmAlert({
      title: "Confirmation",
      message: `Are you sure your want to set ${number} as default?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ loadingNumbers: true });
            Api.call("/accounts/numbers/default", "POST", {
              guid: this.state.guid,
              number: number,
            }).then((response) => {
              this.loadNumbers();
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  removeNumber = (number) => {
    confirmAlert({
      title: "Confirmation",
      message: `Are you sure your want to remove this number?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ loadingNumbers: true });
            Api.call("/accounts/numbers/remove", "POST", {
              guid: this.state.guid,
              number: number,
            }).then((response) => {
              this.loadNumbers();
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  renderColumn = (column, index, rowIndex, row) => {
    if (index === 2) {
      return (
        <td key={index}>
          <FromSwitch
            checked={column}
            onChange={() => {
              if (!this.state.numbers[rowIndex].default_flag) {
                this.setDefaultNumber(
                  this.state.numbers[rowIndex].phone_number
                );
              }
            }}
          />
        </td>
      );
    }

    if (index === row.length - 1) {
      return (
        <td key={index}>
          <ul>
            <li>
              <div
                to="#"
                onClick={() => {
                  this.removeNumber(this.state.numbers[rowIndex].phone_number);
                }}
              >
                <img
                  width="16"
                  src="../../../../images/delete.png"
                  alt="icon"
                />
              </div>
            </li>
          </ul>
        </td>
      );
    }
    return <td key={index}>{column}</td>;
  };

  renderColumnRequests = (column, index, rowIndex, row) => {
    if (
      index === row.length - 1 &&
      this.state.requests[rowIndex].status === 0
    ) {
      return (
        <td key={index}>
          <ul>
            <li>
              <DefaultButton
                className="default-button"
                name="Import"
                onClick={() => {
                  this.onShowModal(this.state.requests[rowIndex].phone);
                }}
              />
            </li>
          </ul>
        </td>
      );
    }
    return <td key={index}>{column}</td>;
  };
  renderColumnKeywords = (column, index, rowIndex, row) => {
    return <td key={index}>{column}</td>;
  };

  renderLoading = () => {
    return (
      <div className="loading-bar">
        <img alt="loading" width={50} src="../../../../images/loading.gif" />
      </div>
    );
  };

  addNumber = (number, setDefault) => {
    this.setState({ showModal: false, showLoadingModal: true });
    Api.call("/accounts/numbers/import", "POST", {
      guid: this.state.guid,
      number: number,
      setDefault: setDefault,
    })
      .then((response) => {
        this.setState({
          loadingNumbers: true,
          showLoadingModal: false,
          showSuccessModal: true,
        });
        this.loadNumbers();
        this.loadRequests();
        this.loadKeywords();
      })
      .catch((error) => {
        this.setState({
          showLoadingModal: false,
          showErrorModal: true,
          importNumberError: error,
        });
      });
  };

  sendLoaRequest = () => {
    confirmAlert({
      title: "Confirmation",
      message: `Are you sure your want to send LOA request to this account?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ loadingLoa: true });
            Api.call("/accounts/loa/send", "POST", { guid: this.state.guid })
              .then((response) => {
                alert("LOA Request sent");
              })
              .catch((error) => {
                alert(error);
              })
              .finally(() => {
                this.setState({ loadingLoa: false });
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  render() {
    if (this.state.loading) {
      return this.renderLoading();
    }
    return (
      <>
        <Card className="mb-3">
          <ThemeHeader
            icon={"../../../images/account.png"}
            cardHeader="Account Detail"
            button=""
            link="/account/create-account"
            class="default-button"
          />
          <Card.Body>
            <Form>
              <Form.Row>
                {Object.keys(this.state.account).map((key) => {
                  if (!fieldTitles[key]) {
                    return null;
                  }
                  return (
                    <Col md={6}>
                      <FormInput
                        readOnly={true}
                        label={fieldTitles[key] || key}
                        id={key}
                        value={this.state.account[key]}
                      />
                    </Col>
                  );
                })}
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>

        <div className="mt-2">Phone Numbers</div>

        <Table
          currentPage={this.state.page}
          loading={this.state.loadingNumbers}
          renderColumn={this.renderColumn}
          loadingClass={"loading-bar-numbers"}
          renderRow={this.renderRow}
          columns={this.state.columns}
          rows={this.state.numbers}
          onButtonOnePress={this.onShowInvite}
        />

        <div className="mt-2">Import Requests</div>
        <Table
          currentPage={this.state.page}
          loading={this.state.loadingRequests}
          renderColumn={this.renderColumnRequests}
          loadingClass={"loading-bar-numbers"}
          renderRow={this.renderRowRequests}
          columns={this.state.columns1}
          rows={this.state.requests}
        />
        <div className="mt-2">Keywords</div>
        <Table
          currentPage={this.state.page}
          loading={this.state.loadingKeywords}
          renderColumn={this.renderColumnKeywords}
          loadingClass={"loading-bar-numbers"}
          renderRow={this.renderRowKeywords}
          columns={this.state.columns2}
          rows={this.state.keywords}
        />

        <ThemeModal
          onSubmit={this.addNumber}
          number={this.state.number}
          showModal={this.state.showModal}
          onChange={(e) => {
            this.setState({ number: e.target.value });
          }}
          onClose={this.onCloseModal}
        />
        <ThemeModalLoading
          type={"loading"}
          showModal={this.state.showLoadingModal}
        />
        <ThemeModalLoading
          type={"success"}
          showModal={this.state.showSuccessModal}
          onClose={() => {
            this.setState({ showSuccessModal: false });
          }}
        />
        <ThemeModalLoading
          type={"error"}
          error={this.state.importNumberError}
          showModal={this.state.showErrorModal}
          onClose={() => {
            this.setState({ showErrorModal: false });
          }}
        />

        {!this.state.loadingNumbers && (
          <div className="d-flex justify-content-end">
            {/* <DefaultButton className="default-button" name="Send LOA Request" loading={this.state.loadingLoa}
                                   onClick={this.sendLoaRequest}/>*/}
            &nbsp; &nbsp; &nbsp;
            <DefaultButton
              className="default-button"
              name="Add New Number"
              onClick={() => {
                this.onShowModal("");
              }}
            />
          </div>
        )}
      </>
    );
  }
}

export default AccountDetail;
