import React, { Component } from "react";
import ThemeHeader from "../../components/themeHeading/ThemeHeader";
import Table from "../../components/Table/Table";
import Api from "../../../api/Api";
import Constants from "../../../utils/Constants";
import Overlay from "../../components/overlay/Overlay";
import ThemeModalLoading from "../../components/modal/ThemeModalLoading";

class LoaStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 8,
      total: 0,
      loading: true,
      error: null,
      sortBy: "companyName",
      sortDirection: "asc",
      columns: [
        { name: "Account Name" },
        { name: "Contact Number" },
        { name: "Email Address" },
      ],
      options: [
        { label: "", value: "" },
        { label: "Hawaii-Aleutian", value: -2 },
        { label: "Alaska", value: -1 },
        { label: "Pacific Time", value: 0 },
        { label: "Mountain Time", value: 1 },
        { label: "Central Time", value: 2 },
        { label: "Eastern Time", value: 3 },
        { label: "Atlantic Time", value: 4 },
      ],
      makeActive: "---",
      currentData: [],
      accounts: [],
      rowSelected: -1,
      overlayActive: false,
      disableButton: true,
      showModal: false,
      modalType: "",
      msg: "",
    };
  }

  componentDidMount() {
    this.loadAccounts();
  }

  onPaginate = (page) => {
    this.setState({ page: page }, this.loadAccounts);
  };

  toggleSortDirection = (sortDirection) => {
    this.setState({ sortDirection: sortDirection }, this.loadAccounts);
  };

  loadAccounts = () => {
    Api.call(
      Constants.GET_LOA_ACCOUNTS_LIST +
        `${this.state.limit}&page=${this.state.page}&sortBy=${this.state.sortBy}&sortDirection=${this.state.sortDirection}`
    )
      .then((accounts) => {
        console.log(JSON.stringify(accounts.data.data));
        this.setState({
          accounts: accounts.data.data,
          total: accounts.data.totalRecords,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  parseRow = (row, index) => {
    return [
      row.account[0]?.companyName,
      row.account[0]?.phoneNumber,
      row.account[0]?.email,
    ];
  };

  renderRow = (row, index) => {
    row = this.parseRow(row, index);
    let columnIndex = 0;
    return (
      <tr key={row.id}>
        {row.map((column) => {
          if(column)
          return this.renderColumn(column, columnIndex++, index, row);
        })}
      </tr>
    );
  };

  renderColumn = (column, index, rowIndex, row) => {
    if (index === 0) {
      return (
        <td
          className={
            this.state.makeActive === rowIndex
              ? "td-pointer td-highlight"
              : "td-pointer"
          }
          onClick={() => this.showAccountDetail(column, rowIndex)}
          key={index}
        >
          {column}
        </td>
      );
    }
    return <td key={index}>{column}</td>;
  };

  showAccountDetail = (column, rowIndex) => {
    this.setState({
      makeActive: rowIndex,
      overlayActive: true,
      disableButton: false,
      currentData: {
        // username: this.state.accounts[rowIndex].account[0].userName,
        // password: this.state.accounts[rowIndex].account[0].password,
        // timezone: this.state.accounts[rowIndex].account[0].timezone
        //   ? this.state.options[
        //       this.state.accounts[rowIndex].account[0].timezone + 3
        //     ].label
        //   : this.state.options[0].label,
        firstname: this.state.accounts[rowIndex].account[0].firstName,
        lastname: this.state.accounts[rowIndex].account[0].lastName,
        email: this.state.accounts[rowIndex].account[0].email,
        company_name: this.state.accounts[rowIndex].account[0].companyName,
        phone: this.state.accounts[rowIndex].account[0].phoneNumber,
        address: this.state.accounts[rowIndex].account[0].address.addressLine1,
        address_2:
          this.state.accounts[rowIndex].account[0].address.addressLine2,
        state: this.state.accounts[rowIndex].account[0].address.stateName,
        city: this.state.accounts[rowIndex].account[0].address.city,
        zip: this.state.accounts[rowIndex].account[0].address.zip,
      },
      rowSelected: rowIndex,
    });
  };

  onHide = () => {
    this.setState({
      makeActive: "---",
      overlayActive: false,
      currentData: [],
      rowSelected: -1,
      disableButton: true,
    });
  };

  onSubmit = () => {
    let account = this.state.accounts[this.state.rowSelected];
    this.setState({ modalType: "", showModal: true });
    Api.call(Constants.IMPORT_NUMBER + account._id, "GET")
      .then((response) => {
        console.log(response);
        if (response.status === Constants.SUCCESS) {
          this.setState({
            modalType: Constants.SUCCESS,
            msg: "Long Code has been created successfully",
          });
        } else {
          this.setState({
            modalType: Constants.ERROR,
            msg:
              "Error : " + (response.reason ? response.reason : response.data),
          });
        }
      })
      .catch((err) => {
        console.log("err=>" + JSON.stringify(err));
        this.setState({
          modalType: Constants.ERROR,
          msg: "Unable to process the request",
        });
      });
  };

  onClose = () => {
    if (this.state.modalType === Constants.SUCCESS) {
      this.setState(
        {
          overlayActive: false,
          currentData: "",
          rowSelected: -1,
          showModal: false,
          disableButton: false,
        },
        this.loadAccounts
      );
    } else {
      this.setState({ showModal: false, disableButton: false });
    }
  };

  render() {
    return (
      <>
        <ThemeHeader
          cardHeader="LOA Status"
          button="Enable Long Code"
          class={
            this.state.disableButton
              ? "orange-button disabled-link"
              : "orange-button"
          }
          onClick={this.onSubmit}
        />

        <Table
          currentPage={this.state.page}
          loading={this.state.loading}
          renderColumn={this.renderColumn}
          renderRow={this.renderRow}
          renderHeaderColumn={this.renderHeaderColumn}
          columns={this.state.columns}
          perPage={this.state.limit}
          sortDirection={this.state.sortDirection}
          total={this.state.total}
          pagination={true}
          onPaginate={this.onPaginate}
          toggleSortDirection={this.toggleSortDirection}
          rows={this.state.accounts}
          onButtonOnePress={this.onShowInvite}
        />

        <Overlay
          active={this.state.overlayActive}
          onHide={this.onHide}
          currentData={this.state.currentData}
          useField={false}
        />

        <ThemeModalLoading
          showModal={this.state.showModal}
          type={this.state.modalType}
          heading="LOA Status"
          msg={this.state.msg}
          onClose={this.onClose}
        />
      </>
    );
  }
}

export default LoaStatus;
