import React, { Component } from "react";
import ThemeHeader from "../../components/themeHeading/ThemeHeader";
import Table from "../../components/Table/Table";
import Api from "../../../api/Api";
import Constants from "../../../utils/Constants";
import Overlay from "../../components/overlay/Overlay";
import ThemeModalLoading from "../../components/modal/ThemeModalLoading";

class AccountCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 8,
      total: 0,
      loading: true,
      error: null,
      sortBy: "companyName",
      sortDirection: "asc",
      columns: [
        { name: "Account Name" },
        { name: "Contact Number" },
        { name: "Email Address" },
      ],
      makeActive: "---",
      currentData: [],
      accounts: [],
      rowSelected: -1,
      overlayActive: false,
      disableButton: true,
      showModal: false,
      modalType: "",
      msg: "",
    };
  }

  componentDidMount() {
    this.loadAccounts();
  }

  onPaginate = (page) => {
    this.setState({ page: page }, this.loadAccounts);
  };

  toggleSortDirection = (sortDirection) => {
    this.setState({ sortDirection: sortDirection }, this.loadAccounts);
  };

  loadAccounts = () => {
    Api.call(
      Constants.GET_PENDING_ACCOUNTS_LIST +
        `${this.state.limit}&page=${this.state.page}&sortBy=${this.state.sortBy}&sortDirection=${this.state.sortDirection}`
    )
      .then((accounts) => {
        this.setState({
          accounts: accounts.data.data,
          total: accounts.data.totalRecords,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  parseRow = (row, index) => {
    return [row.companyName, row.phoneNumber, row.email];
  };

  renderRow = (row, index) => {
    row = this.parseRow(row, index);
    let columnIndex = 0;
    return (
      <tr key={row.id}>
        {row.map((column) => {
          return this.renderColumn(column, columnIndex++, index, row);
        })}
      </tr>
    );
  };

  renderColumn = (column, index, rowIndex, row) => {
    if (index === 0) {
      return (
        <td
          className={
            this.state.makeActive === rowIndex
              ? "td-pointer td-highlight"
              : "td-pointer"
          }
          onClick={() => this.showAccountDetail(column, rowIndex)}
          key={index}
        >
          {column}
        </td>
      );
    }
    return <td key={index}>{column}</td>;
  };

  showAccountDetail = (column, rowIndex) => {
    console.log('M here -->');
    this.setState({
      makeActive: rowIndex,
      overlayActive: true,
      currentData: {
        firstname: this.state.accounts[rowIndex].firstName,
        lastname: this.state.accounts[rowIndex].lastName,
        email: this.state.accounts[rowIndex].email,
        company_name: this.state.accounts[rowIndex].companyName,
        phone: this.state.accounts[rowIndex].phoneNumber,
        address: this.state.accounts[rowIndex].address.addressLine1,
        address_2: this.state.accounts[rowIndex].address.addressLine2,
        state: this.state.accounts[rowIndex].address.stateName,
        city: this.state.accounts[rowIndex].address.city,
        zip: this.state.accounts[rowIndex].address.zip,
      },
      rowSelected: rowIndex,
    });
  };

  onHide = () => {
    this.setState({
      makeActive: "---",
      overlayActive: false,
      currentData: [],
      rowSelected: -1,
      disableButton: true,
    });
  };

  onChange = (key, value) => {  
    // console.log(key,value);

    let accounts = this.state.accounts;
    let disableButton = true;
    if (key === "username") {
      accounts[this.state.rowSelected].userName = value;
    } else if (key === "password") {
      accounts[this.state.rowSelected].password = value;
    } else if (key === "timezone") {
      accounts[this.state.rowSelected].timezone = value.toString();
    }

    if (
      accounts[this.state.rowSelected].userName &&
      accounts[this.state.rowSelected].userName.length > 0 &&
      accounts[this.state.rowSelected].password &&
      accounts[this.state.rowSelected].password.length > 0 &&
      accounts[this.state.rowSelected].timezone &&
      accounts[this.state.rowSelected].timezone.length > 0
    ) {
      disableButton = false;
    }
    this.setState({ accounts: accounts, disableButton: disableButton });
  };

  onSubmit = () => {
    let account = this.state.accounts[this.state.rowSelected];
    this.setState({ modalType: "", showModal: true });
    Api.call(Constants.CREATE_ACCOUNT, "POST", account)
      .then((response) => {
        console.log(response);
        if (response.status === Constants.SUCCESS) {
          this.setState({
            modalType: Constants.SUCCESS,
            msg: account.companyName + " iVision account is created",
          });
        } else {
          this.setState({
            modalType: Constants.ERROR,
            msg: "Error : " + response.reason,
          });
        }
      })
      .catch((err) => {
        console.log("err=>" + JSON.stringify(err));
        this.setState({
          modalType: Constants.ERROR,
          msg:
            account.companyName + " account creation failed, please try again",
        });
      });
  };

  onClose = () => {
    if (this.state.modalType === Constants.SUCCESS) {
      this.setState(
        {
          overlayActive: false,
          currentData: "",
          rowSelected: -1,
          showModal: false,
          disableButton: true,
        },
        this.loadAccounts
      );
    } else {
      this.setState({ showModal: false, disableButton: false });
    }
  };

  render() {
    return (
      <>
        <ThemeHeader
          cardHeader="Ivision Account Creation"
          button="Create Ivision Account"
          icon={"../../../images/.svg"}
          class={
            this.state.disableButton
              ? "orange-button disabled-link"
              : "orange-button"
          }
          onClick={this.onSubmit}
        />

        <Table
          currentPage={this.state.page}
          loading={this.state.loading}
          renderColumn={this.renderColumn}
          renderRow={this.renderRow}
          renderHeaderColumn={this.renderHeaderColumn}
          columns={this.state.columns}
          perPage={this.state.limit}
          sortDirection={this.state.sortDirection}
          total={this.state.total}
          pagination={true}
          onPaginate={this.onPaginate}
          toggleSortDirection={this.toggleSortDirection}
          rows={this.state.accounts}
          onButtonOnePress={this.onShowInvite}
        />

        <Overlay
          active={this.state.overlayActive}
          onHide={this.onHide}
          currentData={this.state.currentData}
          useField={true}
          onChange={this.onChange}
        />

        <ThemeModalLoading
          showModal={this.state.showModal}
          type={this.state.modalType}
          heading="Creating Ivision Account"
          msg={this.state.msg}
          onClose={this.onClose}
        />
      </>
    );
  }
}

export default AccountCreation;
