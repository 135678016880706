import React, {Component} from 'react';
import {Link} from "react-router-dom";

class DefaultButton extends Component {

    render() {
        if(this.props.loading){
            return <div className={this.props.className} type={'button'} onClick={this.props.onClick}>
                Please wait...
            </div>
        }
        if(!this.props.link){
            return <button className={this.props.className} type={'button'} onClick={this.props.onClick}>
                {this.props.name}
            </button>
        }

        return (
            <Link className={this.props.className} to={this.props.link} onClick={this.props.onClick}>
                {this.props.name}
            </Link>
        );
    }
}

export default DefaultButton;
