import React, {Component} from 'react';
import {Form,} from "react-bootstrap";
import classNames from "classnames";

class FormSelectbox extends Component {

    render() {
        const props = this.props;
        return (
            <Form.Group controlId="formGroupEmail" className={props.class}>
                <Form.Label>{props.label}</Form.Label>
                <Form.Control className={classNames(props.error && 'error')}  as="select" onChange={this.props.onChange} value={props.value}>
                    {props.options.map(option=>{
                        return <option value={option.value}>{option.label}</option>
                    })}
                </Form.Control>
                <span className="err-input">{props.error}</span>
            </Form.Group>
        );
    }
}

export default FormSelectbox;
