let config = {
  development: {
    // API_URL: "http://localhost:3000/core/",
    API_URL: "https://qazingitcore2.zingitapps.com/core/",
    OSAdmin_URL: "https://qaosadmin.zingitapps.com"
  },
  qa: {
    API_URL: "https://qazingitcore2.zingitapps.com/core/",
    OSAdmin_URL: "https://qaosadmin.zingitapps.com"
  },
  rc: {
    API_URL: "https://rczingitcore2.zingitapps.com/core/",
    OSAdmin_URL: "https://rcosadmin.zingitapps.com"
  },
  production: {
    OSAdmin_URL: "https://osadmin.zingitapps.com",
    API_URL: "https://zingitcore2.zingitapps.com/core/"
  },
};

export default config;
