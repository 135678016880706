import React, {Component} from 'react';

class FormCheckbox extends Component {

    render() {
        const props = this.props;
        return (
            <>
                <input type="checkbox" className="d-none" id={props.id} value={props.value} checked={this.props.checked} onChange={this.props.onChange}/>
                <label htmlFor={props.id} className="custom-radio">
                    <img width="16" src="../../../../images/check.png" className="active" alt="icon"/>
                    <img width="16" src="../../../../images/uncheck.png" className="inactive" alt="icon"/>
                    <span>{props.name}</span>
                </label>
            </>
        );
    }
}

export default FormCheckbox;
