import React, { Component } from "react";
import { Navbar } from "react-bootstrap";

class LoginHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,

    };
  }



  toggleClass = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };
  
  render() {
    return (
      <header className={this.state.active ? "header show" : "header show"}>
        <div className="pcMenu">
          <Navbar
            bg="transparent"
            variant="light"
            className="justify-content-between align-items-center"
          >
           <div className="all-screens-head d-flex align-items-center">
              <img
                className="zingitLogo"
                src="../../../../images/plan-zingit.svg"
                alt="logo"
              />
              <span class="screen-name">AMT</span>
            </div>
            {/* <div className="d-flex align-items-center">
              <div className="d-flex p-0" id="navbarSupportedContent">
                <li className="nav-item">
                  <a className="nav-link" href="">
                    ACCCOUNT MANAGEMENT TOOL
                  </a>
                </li>
              </div>
            </div> */}
          </Navbar>
        </div>
      </header>
    );
  }
}

export default LoginHeader;
