import React from 'react'
import {Route} from 'react-router-dom';
import MainHeader from "./Header/MainHeader";
import Sidebar from "./sidebar/Sidebar";
import AccountDetail from "../main/accountDetail/AccountDetail";
import AllAccounts from "../main/allAccounts/AllAccounts";
import CreateAccount from "../main/createAccount/CreateAccount";
import AccountCreation from "../main/accountCreation/AccountCreation";
import LoaStatus from "../main/loaStatus/LoaStatus";
import UpgradePlan from "../main/upgradePlan/UpgradePlan";

const MainRoute = ({component: Component}) => {

    

    return (
        <Route
            render={props => (
                <div className="content-container">
                    {/* Header */}
                    <MainHeader/>

                    {/* Sidebar */}
                    <Sidebar active={'accounts'}/>
                    <div className="screens">
                        <Route exact path="/allAccounts" component={AllAccounts}/>
                        <Route exact path="/account/account-detail" component={AccountDetail}/>
                        <Route exact path="/account/create-account" component={CreateAccount}/>
                        <Route exact path="/requests/accountCreation" component={AccountCreation}/>
                        <Route exact path="/requests/loaStatus" component={LoaStatus}/>
                        <Route exact path="/requests/upgradePlan" component={UpgradePlan}/>
                    </div>
                </div>
            )}
        />
    )
};

export default MainRoute;
