import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import DefaultButton from "../buttons/DefaultButton";

class ThemeModalLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: "",
    };
  }
  submit = () => {
    this.props.onSubmit(this.state.number);
  };

  renderSuccess() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.onClose}
        centered
        className="modal patientsModal"
      >
        <Modal.Header className="greenTheme">
          <img alt="success-modal" src="../../../../images/success-modal.svg" />
        </Modal.Header>
        <div className="modal-body py-4 d-flex flex-column align-items-center">
          <div className="py-4 modal-body-items">{this.props.msg}</div>
          <DefaultButton
            className="cancel-button"
            name="Close"
            onClick={this.props.onClose}
          />
        </div>
      </Modal>
    );
  }
  renderError() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.onClose}
        centered
        className="modal patientsModal"
      >
        <Modal.Header className="redTheme">
          <img alt="close Button" src="../../../../images/close-button.svg" />
        </Modal.Header>
        <div className="modal-body py-4 d-flex flex-column align-items-center">
          <div className="py-4">{this.props.msg}</div>
          <DefaultButton
            className="cancel-button"
            name="Close"
            onClick={this.props.onClose}
          />
        </div>
      </Modal>
    );
  }
  render() {
    if (this.props.type === "success") {
      return this.renderSuccess();
    }
    if (this.props.type === "error") {
      return this.renderError();
    }
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.onClose}
        centered
        className="modal patientsModal"
      >
        <div className="modal-body py-4 d-flex flex-column align-items-center">
          <h5 className="modal-title mb-3" id="exampleModalLongTitle">
            {this.props.heading}
          </h5>
          <img alt="loading" width={50} src="../../../../images/loading.gif" />
          Please wait. This might take a while..
        </div>
      </Modal>
    );
  }
}

export default ThemeModalLoading;
