import React from "react";
import config from "../../../config/config";
import MainHeader from "../../components/Header/MainHeader";
const env = process.env.REACT_APP_ENV || "qa";

const OSAdmin = () => {
    const sessionKey = localStorage.getItem('sessionKey') || '';
    const sanitizedSessionKey = encodeURIComponent(sessionKey);
    const BASE_URL = config[env].OSAdmin_URL;
    const iframeURL = `${BASE_URL}?token=${sanitizedSessionKey}`;
    return (
        <>
            <MainHeader />
            <div className="screens p-0">
                <iframe
                    className="iframe"
                    title="Online Scheduling Admin"
                    src={iframeURL}
                />
            </div>
        </>
    );
};

export default OSAdmin;


