import React from "react";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";

const Popup = props => {

  const loadData = (val) => {
    props.downloadCSV(val);
  }

  const onChangeRadio = (e) => {
    props.toggleLoading(true);

    props.toggleRadio(e.target.value);
    loadData(e.target.value);
  }

  let data;

  if(!props.disableBtn) {
    if(props.radioBtn.dataPartial) {
      data= props.accountPartialData;
    }
    if(props.radioBtn.dataFull) {
      data= props.accountFullData;
    }
  }
  
  return (
    <>
        {props.showPopup && (
        <div className="popup">
          <div className="overlay" onClick={props.togglePopup}></div>
          <div className="popup-content">
            <div className="popup-header">
            <p>What do you want to export?</p>
            </div>
            <div className="popup-body">
            <form>
            <div onClick={onChangeRadio}>
            <div class="radio-form">
              <input class="input-1" type="radio" name="radioD" value="radioBtn1" id="radioDefault1"/>
                Current page's data.           
            </div>
            <div class="radio-form">
              <input class="input-2" type="radio" name="radioD" value="radioBtn2" id="radioDefault2"/>
                Complete data of all accounts.
            </div>
            </div>
            </form>
              <Button className="btn-export" variant="primary" type="button">Export</Button>
              {props.loading && (
                <Button className="btn-export" variant="primary" type="button">Loading...</Button>
              )}
              
            {!props.disableBtn && (
            <CSVLink headers={props.headersCsv} data={data} filename="exportData.csv">
              <Button className="btn-export" variant="primary" type="button">Export</Button>
            </CSVLink>
            )}
            
            </div>
            <img className="close-popup" onClick={props.togglePopup}
                src="../../../../../images/cancel-popup.svg" alt="cancel"/>
          </div>
        </div>)
        }
    </>
  );
};
 
export default Popup;
 