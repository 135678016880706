import React, { Component } from "react";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      collapse: true,
      sideCollapse: false,
    };
  }

  componentDidMount() {
    if (window.location.pathname.includes("/requests")) {
      this.setState({ collapse: false });
    }
  }

  render() {
    let url = window.location.pathname;
    return (
      <div className= "aside">
        <div className="aside-header">
          {/* <div className="nav-button">
            <button onClick={() => this.toggleSideCollapse()}>
              <img
                className="bars"
                src="../../../../images/bars-solid.svg"
                alt="bars"
              />
            </button>
          </div> */}
          <img
            className="zingitLogo"
            src="../../../../images/Zingit-Primary-Logo.svg"
            alt="logo"
          />
        </div>

        {/* <ul className={this.state.sideCollapse ? "sideCollapse" : ""}>
          <AsideLink
            icon={
              url.includes("/allAccounts")
                ? "../../../../images/account.svg"
                : "../../../../images/account-black.svg"
            }
            link="/allAccounts"
            screen="All Accounts"
            class={`aside-link ${url.includes("/allAccounts") ? "active" : ""}`}
          />
          <AsideLink
            link="#"
            id="main"
            icon={
              url.includes("/requests")
                ? "../../../../images/account-pending.svg"
                : "../../../../images/account-pending-black.svg"
            }
            screen="Account Pending"
            class={`aside-link ${url.includes("/requests") ? "active" : ""}`}
            onClick={this.onShowHide}
          />
          <div className="down-arrow" onClick={this.onShowHide}>
            <img
              src={
                url.includes("/requests")
                  ? "../../../../images/down-arrow-white.svg"
                  : "../../../../images/down-arrow.svg"
              }
              alt="arrow"
            />
          </div>
          <div
            className={this.state.collapse ? "SubMenus hide" : "SubMenus"}
            id="submenu"
          >
            <li>
              <AsideLink
                link="/requests/accountCreation"
                screen="Ivision Account Creation"
                class={`aside-link aside-margin ${
                  url.includes("/requests/accountCreation") ? "sideactive" : ""
                }`}
              />
            </li>
            <li>
              <AsideLink
                link="/requests/loaStatus"
                screen="LOA Status"
                class={`aside-link aside-margin ${
                  url.includes("/requests/loaStatus") ? "sideactive" : ""
                }`}
              />
            </li>
            <li>
              <AsideLink
                link="/requests/upgradePlan"
                screen="Upgrade Zingit Plan"
                class={`aside-link aside-margin ${
                  url.includes("/requests/upgradePlan") ? "sideactive" : ""
                }`}
              />
            </li>
          </div>
              </ul>*/}
      </div> 
    );
  }
}

export default Sidebar;
