let Constants = {
  GENERATE_KEY: "generateKey?email=",
  GET_ACCOUNTS_LIST: "getAccountsList?keyword=",
  GET_PENDING_ACCOUNTS_LIST: "getPendingAccountsList?limit=",
  CREATE_ACCOUNT: "createProviderAccount",
  GET_LOA_ACCOUNTS_LIST: "getLoaAccountsList?limit=",
  IMPORT_NUMBER: "importNumber?id=",
  ADD_UPDATE_SETTINGS: "persistence/addOrUpdateAccountSettings",
  GET_SETTINGS: "persistence/getAccountSettings?accountId=",
  GET_INTAKEQ_DETAILS: "getIntakeQDetails?accountId=",
  UPDATE_EMAIL: "updateIntakeQEmail?id=",
  UPDATE_ALERT_TEXT: "persistence/addOrUpdateAlertText",
  CREATE_OR_RETRIEVE_KEYWORD: "createCompaign",
  UPDATE_ENABLE_ZINGIT_FLAG:"addOrUpdateZingit2flag?",
  UPDATE_LOCATION_ID:"updateLocationId",
  // messages
  SUCCESS: "success",
  ERROR: "error",
};

export default Constants;
