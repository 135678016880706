import React from 'react'
import {Route} from 'react-router-dom';
import Login from "../auth/login/Login";

const AuthRoute = ({component: Component}) => {

    return (
        <Route
            render={props => (
                <Route exact path="/" component={Login}/>
               
            )}
        />
    )
};

export default AuthRoute;
