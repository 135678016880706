import config from "../config/config";

const env = process.env.REACT_APP_ENV || "production";
export default class Api {
  static user = null;
  static selected = [];
  static sendTo = [];

  static BASE_URL = "";

  static call(url, method = "GET", data) {
    let BASE_URL = config[env].API_URL;

    console.log("Calling API: " + method + " " + url);
    if (data) {
      console.log(data);
    }

    return new Promise((resolve, reject) => {
      fetch(BASE_URL + url, {
        method: method || "GET",
        body: JSON.stringify(data),
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
          ZingitAuthEmail: localStorage.getItem("AMT_ZINGIT_AUTH_EMAIL"),
          ZingitAuth: localStorage.getItem("AMT_ZINGIT_AUTH_SECRET"),
        },
      })
        .then((response) => {
          // response only can be ok in range of 2XX
          if (response.ok) {
            // you can call response.json() here too if you want to return json
            resolve(response.json());
          } else {
            //handle errors in the way you want to
            console.log(response.status);
            switch (response.status) {
              // case 401:
              //   localStorage.removeItem("AMT_ZINGIT_AUTH_EMAIL");
              //   localStorage.removeItem("AMT_ZINGIT_AUTH_SECRET");
              //   window.location.href = "/";
              //   break;
              case 404:
                console.log("Object not found");
                break;
              case 500:
                console.log("Internal server error");
                break;
              default:
                console.log("Some error occured");
                break;
            }

            resolve(response.json());
          }
        })
        .catch((error) => {
          //it will be invoked mostly for network errors
          //do what ever you want to do with error here
          console.log(error);
          reject("Error");
        });
    });
  }

  static coreAPICall(url, method = "GET", data) {
    let BASE_URL = config[env].API_URL;
    return new Promise((resolve, reject) => {
      fetch(BASE_URL + url, {
        method: method || "GET",
        body: JSON.stringify(data),
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json"
        },
      })
        .then((response) => {
          resolve(response.json());
        })
        .catch((error) => {
          //it will be invoked mostly for network errors
          //do what ever you want to do with error here
          console.log(error);
          reject("Error");
        });
    });
  }
}
