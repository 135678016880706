import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Api from "../../../api/Api";
import Constants from "../../../utils/Constants";
import Popup from "./components/PopupExport"
import ReactTooltip from 'react-tooltip';

class ThemeHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headersCsv : [
        { label: "Company Name", key: "companyName"},
        { label: "Phone Number", key: "phoneNumber"},
        { label: "Email", key: "email"},
        { label: "ZForm Date", key: "enableZFormsDate"},
        { label: "Enable Persistence", key: "enablePersistence"},
        { label: "Enable ZForms", key: "enableZForms"},
        { label: "Enable ZSuite", key: "enableZSuite"},
        { label: "Enable Missed Appointment Alerts", key: "enableMissedAppointmentAlerts"},
      ],
      showPopup: false,
      accountPartialData: [],
      accountFullData: [],
      radioBtn: {
        dataPartial: true,
        dataFull: false
      },
      isPartialDataLoaded: false,
      isFullDataLoaded: false,
      disableBtn: true,
      loading :false 
    };
    
  }

  loadAccounts = async (val) => {
     
    // radioBtn1 ~ 1st radio button ~ current page'data
    if(val==="radioBtn1") {
    const response = await Api.call(
    Constants.GET_ACCOUNTS_LIST +
        `${this.props.keyword.length === 0 ? " " : this.props.keyword}&limit=${this.props.limit}
        &page=${this.props.page}&sortBy=${this.props.sortBy}&sortDirection=${this.props.sortDirection
        }&onlyForAmt=1`
        )
        .then((response) => {
          return response;
        });
        return response;  
    }
    
    // radioBtn2 ~ 2nd radio button ~ all account's data
    if(val==="radioBtn2") {
          const response = await Api.call(
          Constants.GET_ACCOUNTS_LIST +
          `${this.props.keyword.length === 0 ? " " : this.props.keyword}&limit=${-1}
          &page=${1}&sortBy=${this.props.sortBy}&sortDirection=${this.props.sortDirection
          }&onlyForAmt=1`
          )
          .then((response) => {
            return response;
          });
          return response
      }
  }

  downloadReport = async (val) => {
    // radioBtn1 ~ 1st radio button ~ current page'data
    if(val === "radioBtn1") {
    const accData = await this.loadAccounts(val);
    const data = accData.data.data;
    for(let i=0 ;i<data.length ;i++) {
      data[i].enableZFormsDate=data[i].settings?.enableZFormsDate;
      data[i].enableZForms=data[i].settings?.enableZForms??false;
      data[i].enableZSuite=data[i].settings?.enableZSuite??false;
      data[i].enableMissedAppointmentAlerts=data[i].settings?.enableMissedAppointmentAlerts??false;
      data[i].enablePersistence=data[i].settings?.enableZingitPersistence?.fullPersistence??false;
    }
    this.state.accountPartialData = data;
    this.setState({
      isPartialDataLoaded: true,
      isFullDataLoaded: false,
      disableBtn: false,
      loading: false
    });
    }

    // radioBtn2 ~ 2nd radio button ~ all account's data
    if(val === "radioBtn2") {
      this.setState({
        isPartialDataLoaded: false,
        disableBtn: true
      });
      const accData = await this.loadAccounts(val);
      const data=accData.data.data;
      for(let i=0 ;i<data.length ;i++) {
      data[i].enableZFormsDate=data[i].settings?.enableZFormsDate;
      data[i].enableZForms=data[i].settings?.enableZForms??false;
      data[i].enableZSuite=data[i].settings?.enableZSuite??false;
      data[i].enableMissedAppointmentAlerts=data[i].settings?.enableMissedAppointmentAlerts??false;
      data[i].enablePersistence=data[i].settings?.enableZingitPersistence?.fullPersistence??false;
    }
    this.state.accountFullData=data;
      this.setState({
        isFullDataLoaded: true,
        disableBtn: false,
        loading: false
      });
      }
    
  }

  togglePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup
    });
    if(this.state.showPopup) {
    const pagination=document.querySelector(".float-right");
    pagination.style.cssText = "opacity : 1";
    const toggleBtns=document.querySelectorAll(".enable-disab-auto");
    toggleBtns.forEach((btn) => {
      btn.style.cssText = "opacity : 1";
    });
    }
  }

  handleExportClick = () => {
    this.togglePopup();
    const pagination=document.querySelector(".float-right");
    pagination.style.cssText = "opacity : 0.2";
    pagination.style.pointerEvents = "none";
    const toggleBtns=document.querySelectorAll(".enable-disab-auto");
    toggleBtns.forEach((btn) => {
      btn.style.cssText = "opacity : 0.2";
      btn.style.pointerEvents = "none";
    });
  }

  toggleRadio = (val) => {
    if(val === "radioBtn1") this.setState({
      radioBtn: {
        dataPartial: true,
        dataFull: false
      }
    });

    if(val === "radioBtn2") this.setState({
      radioBtn: {
        dataPartial: false,
        dataFull: true
      }
    });
  }

  downloadCSV = (val) => {
    this.setState({
      loading: true
    });
    this.downloadReport(val);
  }

  render() {
    const props = this.props;
    const url = window.location.pathname;
    const showExport = url?.includes('/allAccounts');

    return (
      <Card.Header className="d-flex align-items-center justify-content-between screen-header">
        <div className="d-flex flex-row align-items-center theme-header">
          <span onClick={this.props.onclickShow}>{props.cardHeader}</span>
        </div>
        <div className="mandatory" id="search-id">
          <div className="hoverExport">
            <ReactTooltip id="registerTip" place="left" effect="solid" type="info">
              Export
           </ReactTooltip>
           { showExport && <div className="export-icon">
              <img src="../../../../images/export-icon.png" alt="export" data-tip data-for="registerTip" onClick={this.handleExportClick}/>
            </div> }
          </div>
        <Popup
          togglePopup={this.togglePopup}
          toggleRadio={this.toggleRadio}
          downloadCSV={this.downloadCSV}
          showPopup={this.state.showPopup}
          loading={this.state.loading}
          headersCsv={this.state.headersCsv}
          disableBtn={this.state.disableBtn}
          radioBtn={this.state.radioBtn}
          accountPartialData={this.state.accountPartialData}
          accountFullData={this.state.accountFullData}
          toggleLoading={(val) => {this.setState({loading:val});}}
        />
          {this.props.onSearch && (
            <input
              placeholder={"Search"}
              className="search-input"
              value={this.props.keyword}
              type={"search"}
              onChange={(e) => {
                this.props.onSearch(e.target.value);
            }}
            />
          )}          

          {props.button && (
            <Link className={props.class} onClick={this.props.onClick}>
              {props.button}
            </Link>
          )}
        </div>
        
      </Card.Header>
    );
  }
}

export default ThemeHeader;
